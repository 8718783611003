import { Farm, Network, SolanaNetwork } from "./interfaces/network";
import { Pair } from "./interfaces/token";
import {
  farmToken,
  newFarmToken,
  // farmTokenETH,
  pairs,
  newPairs,
  newPairOmniRewards,
  // pairsETH,
  // tokenETH,
} from "./token-config";

// Farms configuration
const bscFarms: Farm[] = [
  {
    contractAddress: process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
    rewardToken: farmToken.EVRY_TOKEN,
    pairs: pairs.filter((pair: Pair) =>
      process.env.REACT_APP_ENABLE_FEATURE_VELO === "false"
        ? pair.id !== "VELO_TOKEN"
        : pair.id
    ),
    tokens: farmToken,
    ABIName: "Farms",
  },
  {
    contractAddress: process.env.REACT_APP_COMMON_FARM_CONTRACT_ADDRESS,
    rewardToken: newFarmToken.VELO_TOKEN,
    pairs: newPairs,
    tokens: newFarmToken,
  },
  {
    contractAddress: process.env.REACT_APP_COMMON_FARM_CONTRACT_ADDRESS,
    rewardToken: newFarmToken.OMNI_TOKEN,
    pairs: newPairOmniRewards,
    tokens: newFarmToken,
  },
];

// Mainnet and Testnet Networks
const networksMainnet: Record<number, Network> = {
  56: {
    id: 1,
    name: "BSC Mainnet",
    chainId: 56,
    image: require("/src/assets/icons/network/bsc.svg").default,
    code: "BNB",
    display: "BNB",
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
    ],
    chainExplorerURL: "https://bscscan.com/",
    farm: bscFarms,
    providers: [
      {
        name: "Metamask",
        image: require("/src/assets/icons/provider/metamask.svg").default,
      },
      {
        name: "Coin98",
        image: require("/src/assets/icons/provider/coin98.svg").default,
      },
      {
        name: "Bitget Wallet",
        image: require("/src/assets/icons/provider/bitgetWallet.svg").default,
      },
    ],
  },
};

const networksTestnet: Record<number, Network> = {
  97: {
    id: 0,
    name: "BSC Testnet",
    chainId: 97,
    image: require("/src/assets/icons/network/bsc.svg").default,
    code: "BNB",
    display: "BNB",
    rpcUrls: [
      "https://data-seed-prebsc-1-s1.binance.org:8545",
      "https://data-seed-prebsc-1-s2.binance.org:8545",
      "https://data-seed-prebsc-1-s3.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s2.binance.org:8545",
      "https://data-seed-prebsc-2-s3.binance.org:8545",
    ],
    chainExplorerURL: "https://testnet.bscscan.com/",
    farm: bscFarms,
    providers: [
      {
        name: "Metamask",
        image: require("/src/assets/icons/provider/metamask.svg").default,
      },
      {
        name: "Coin98",
        image: require("/src/assets/icons/provider/coin98.svg").default,
      },
      {
        name: "Bitget Wallet",
        image: require("/src/assets/icons/provider/bitgetWallet.svg").default,
      },
    ],
  },
};

// Solana networks configuration
const networkSolMainnet: SolanaNetwork = {
  name: "Solana",
  chainId: "mainnet",
  image: require("/src/assets/icons/network/sol.svg").default,
  code: "SOL",
  display: "Solana",
  chainExplorerURL: "https://solscan.io/",
  providers: [
    {
      name: "Phantom",
      image: "/src/assets/icons/provider/phantom.svg",
    },
  ],
};

const networkSolDevnet: SolanaNetwork = {
  name: "Solana Devnet",
  chainId: "devnet",
  image: require("/src/assets/icons/network/sol.svg").default,
  code: "SOL",
  display: "Solana",
  chainExplorerURL: "https://solscan.io/",
  providers: [
    {
      name: "Phantom",
      image: "/src/assets/icons/provider/phantom.svg",
    },
  ],
};

// Environment-based network configurations
const networks =
  process.env.REACT_APP_IS_PROD?.toUpperCase() === "TRUE"
    ? networksMainnet
    : networksTestnet;
const networkSol =
  process.env.REACT_APP_IS_PROD?.toUpperCase() === "TRUE"
    ? networkSolMainnet
    : networkSolDevnet;

export { networks, networkSol };
