import { useEffect, useMemo, useRef, useState } from "react";
import { getDate, getDateByUnix, period as getPeriod } from "../utils/datetime";
import dayjs from "dayjs";

interface Prop {
  onForceCountDown: () => void;
  startTime?: number;
  endTime?: number;
  periodFix?: number;
}

const usePeriodHelpers = ({
  onForceCountDown,
  startTime = 1635397706,
  endTime = 1638259544,
  periodFix = 0,
}: Prop) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [countDown, setCountdown] = useState<number>(0);

  const currentPeriod = useMemo(() => {
    const nowUnix = getDateByUnix().unix();
    const startPeriod = nowUnix > startTime ? nowUnix : startTime;

    const period = getPeriod(startPeriod, endTime);
    return period <= 0 ? 0 : period;
  }, [startTime, endTime]);

  const periodRange = useMemo(() => {
    const now = dayjs();
    const startTimeDate = dayjs.unix(startTime);
    const endTimeDate = periodFix
      ? startTimeDate.add(periodFix, "day")
      : dayjs.unix(endTime);

    if (now.isBefore(startTimeDate.subtract(24, "hour")) || now.isBetween(startTimeDate, endTimeDate)) {
      const startPeriod = startTimeDate.format("D MMM YYYY");
      const endPeriod = endTimeDate.format("D MMM YYYY");
      return `${startPeriod} - ${endPeriod}`;
    }

    if (now.isBetween(startTimeDate.subtract(24, "hour"), startTimeDate)) {
      const diffInSeconds = startTimeDate.diff(now, "second");
      setCountdown(diffInSeconds);
      return null; // Return null temporarily; actual countdown is managed elsewhere
    }

    return "Period has ended";
  }, [startTime, endTime, periodFix]);

  useEffect(() => {
    if (countDown > 0) {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (timer.current) {
      clearTimeout(timer.current);
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [countDown]);

  const countdownDisplay = useMemo(() => {
    if (countDown > 0) {
      return dayjs.duration(countDown * 1000).format("HH : mm : ss");
    }
    return null;
  }, [countDown]);

  const isShowPeriodCountdown = useMemo(() => {
    const nowUnix = getDateByUnix().unix();
    return (!countDown || countDown <= 0) && nowUnix >= startTime;
  }, [countDown, startTime]);

  const countDownTimer = (second: number) => {
    setCountdown(second);
    if (second <= 0) {
      onForceCountDown();
    }
    
  };
  const clearTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  return {
    getPeriod,
    currentPeriod,
    periodRange: periodRange || countdownDisplay,
    isShowPeriodCountdown,
    countDownTimer,
    clearTimer,
  };
};

export default usePeriodHelpers;
