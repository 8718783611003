import { ethers } from "ethers";
import { useEthereum } from "../contexts/etherruemContext";

const useErrorHelpers = () => {
  const ethereum = useEthereum();

  const getErrorReason = async (txHash: string) => {
    if (!ethereum.web3) return undefined;
    if (!txHash || txHash === null || txHash === undefined) return undefined;
    const provider = await ethers.getDefaultProvider(process.env.chainRPC);
    const tx = await provider.getTransaction(txHash);

    if (tx === null || tx === undefined) return undefined;
    const code = await provider.call(tx);
    let codeString = `0x${code.substr(138)}`.replace(/0+$/, "");
    // If the codeString is an odd number of characters, add a trailing 0
    if (codeString.length % 2 === 1) {
      codeString += "0";
    }
    const encodeError = ethereum.web3.utils.toAscii(codeString);
    return mappingError(encodeError);
  };
  const mappingError = (error: any) => {
    let returnError;
    console.log("error=", error);
    switch (true) {
      case String(error).includes("INSUFFICIENT_OUTPUT_AMOUNT"):
        returnError =
          "Transaction failed due to price changes unfavorably more than % of slippage settings";
        break;
      case String(error).includes("EXPIRED"):
        returnError =
          "The transaction failed because it was not completed within the timeframe specified in the transaction deadline settings.";
        break;
      default:
        returnError = undefined;
    }
    return returnError;
  };
  const isErrorClass = (obj: {}) => {
    return Object.prototype.toString.call(obj) === "[object Error]";
  };

  return { getErrorReason, isErrorClass };
};

export default useErrorHelpers;
