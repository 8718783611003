import React, { useState, useRef, useEffect } from "react";
import PageHeader from "../../components/pageHeader";
import LiquidityCardLp from "../../components/liquidity/cardLP";
import config from "../../config";
import { LiquidityPool } from "../../interfaces/token";
import { useEthereum } from "../../contexts/etherruemContext";
import { useWallet } from "../../contexts/walletContext";
import BigNumber from "bignumber.js";
import { lpType } from "../../constants";
import { useTokenHelpers } from "../../hooks/useTokenHelpers";
import useLiquidity from "../../hooks/useLiquidityHelpers";
import RemoveModal from "../../components/liquidity/removeModal";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";
import Web3 from "web3";

export interface lpExtra extends LiquidityPool {
  lpBalance: number;
  token0Amount: BigNumber;
  token1Amount: BigNumber;
  percentOfPoolShare: BigNumber;
  isRemovingStatus: boolean;
  id: string;
  isAllowance: boolean;
}

const LiquidityPools: React.FC = () => {
  // const [joinCounter, setJoinCounter] = useState<number>(0);
  // const [lpTokens, setLpTokens] = useState<LpToken[]>([]);
  // const [isLoadFinish, setIsLoadFinish] = useState<boolean>(true);

  const [configLPList, setConfigLPList] = useState<LiquidityPool[]>([]);
  const [configDMMLPList, setConfigDMMLPList] = useState<LiquidityPool[]>([]);
  const [displayLPList, setDisplayLPList] = useState<any[]>([]);
  const [lpTokens, setLpTokens] = useState<lpExtra[]>([]);
  const [joinCounter, setJoinCounter] = useState<number>(0);
  const [isLoadFinish, setIsLoadFinish] = useState<boolean>(false);
  const [triggerLp, setTriggerLp] = useState<boolean>(false);

  const ethereum = useEthereum();
  // const wallet = useWallet();

  const { checkAllowanceToSpenderFromMax } = useTokenHelpers();
  const {
    getToken0AndToken1InLp,
    getTotalPoolShare,
    getReserveInPool,
    isReady,
  } = useLiquidity();
  const { walletApi } = useUnifiedWallet();

  const removeLPModalRef = useRef<{ open: (lp: lpExtra) => void }>(null);

  const removeButtonClick = (lp: lpExtra) => {
    if (removeLPModalRef.current) {
      removeLPModalRef.current.open(lp);
    }
  };

  const addToken = (lp: lpExtra) => {
    console.log("Add token clicked for", lp);
  };

  // const onClickDocument = (id: string) => {
  //   console.log("Document clicked for", id);
  // };

  const onConfirmRemove = (
    lpTokenID: string,
    pendingStatus: boolean,
    state = "remove"
  ) => {
    setLpTokens((lpTokens) =>
      lpTokens.map((lpToken) => {
        if (lpToken.id === lpTokenID) {
          lpToken.isRemovingStatus = pendingStatus;
        }
        return lpToken;
      })
    );
    if (!pendingStatus && state === "remove") {
      // const ref = `liquidityExpansionPanel${lpTokenID}`
      getAllLpInfo();
      // this.$refs[ref][0].handleExpand(true)
    }
  };

  const getAllLpInfo = async () => {
    // if (!ethereum.contracts || !ethereum.web3 || !walletApi.account.current) {
    //   setTimeout(() => {
    //     getAllLpInfo();
    //   }, 2000);
    //   return;
    // }
    console.log("getLpInfo configLPList", configLPList);

    let zeroLP: any[] = [];
    let ownLP: any[] = [];

    await Promise.all(
      configLPList
        .filter((lp) => {
          if (lp.isHidden) {
            return false; // skip
          }
          if (lp.address === "") {
            return false; // skip
          }
          return true;
        })
        .map(async (element) => {
          const lp = await getLpInfo(element);
          if (lp?.lpBalance && Number(lp?.lpBalance.toFixed(12)) > 0) {
            ownLP.push(lp);
          } else if (lp) zeroLP.push(lp);
        })
    );

    ownLP = sortLP(ownLP);
    zeroLP = sortLP(zeroLP);

    setLpTokens(JSON.parse(JSON.stringify(ownLP.concat(zeroLP))));

    setJoinCounter(ownLP.length);

    setIsLoadFinish(true);
  };

  const sortLP = (array: any[]) => {
    return array.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
  };

  const getLpInfo = async (lp: LiquidityPool) => {
    if (!walletApi.hasAddress) return;
    const tokenSymbol = lp.code.replace("/", "_");
    const walletCurrent = walletApi.account.current || "";
    const poolInfo = await getTotalPoolShare(tokenSymbol, walletCurrent);

    if (!poolInfo) return;
    // if poolInfo is {}, return
    if (Object.keys(poolInfo).length === 0 && poolInfo.constructor === Object) {
      return;
    }

    const lpBalance = Web3.utils.fromWei(poolInfo.lpBalance, "ether");
    let token0Amount = BigNumber("0");
    let token1Amount = BigNumber("0");
    let percentOfPoolShare = BigNumber("0");
    const isRemovingStatus = false;
    const id = tokenSymbol;
    let isAllowance = false;

    const totalSupply = BigNumber(poolInfo.totalSupply);
    if (parseFloat(lpBalance) > 0) {
      const balance = BigNumber(poolInfo.lpBalance);
      percentOfPoolShare = balance.div(totalSupply).multipliedBy(100);

      // TODO getReserve need to change call with new method -- DMM getTradeInfo
      const tokenInPool = await getReserveInPool(tokenSymbol);

      // if (!tokenInPool || !tokenInPool._reserve0 || !tokenInPool._reserve1)
      //   return;

      let reserve0 = tokenInPool._reserve0
        ? BigNumber(Web3.utils.fromWei(tokenInPool._reserve0, "ether"))
        : BigNumber("0");
      let reserve1 = tokenInPool._reserve1
        ? BigNumber(Web3.utils.fromWei(tokenInPool._reserve1, "ether"))
        : BigNumber("0");

      // check swap token0 and token1
      const tokens = await getToken0AndToken1InLp(tokenSymbol);
      if (
        lp.token0.address?.toUpperCase() ===
        tokens.token1?.address?.toUpperCase()
      ) {
        // swap 0<->1
        const temp = reserve0;
        reserve0 = reserve1;
        reserve1 = temp;
      }

      token0Amount = BigNumber(percentOfPoolShare)
        .multipliedBy(reserve0)
        .div(100);

      token1Amount = BigNumber(percentOfPoolShare)
        .multipliedBy(reserve1)
        .div(100);

      let routerAddress = ethereum.contracts.ammRouter._address;
      if (lp.lpType === lpType.DMM_TYPE) {
        routerAddress = ethereum.contracts.dmmRouter._address;
      }
      console.log("tokenSymbol", tokenSymbol);
      isAllowance = await checkAllowanceToSpenderFromMax(
        tokenSymbol,
        walletCurrent,
        routerAddress,
        BigNumber(poolInfo.lpBalance)
      );
    }

    const lpResult: lpExtra = {
      ...lp,
      lpBalance: parseFloat(BigNumber(lpBalance).toPrecision(16)),
      token0Amount,
      token1Amount,
      percentOfPoolShare,
      isRemovingStatus,
      id,
      isAllowance,
    };

    return lpResult;
  };

  // useEffect(() => {
  // Clear all notifications
  // dispatch(clearAllNotifications());

  // setConfigLPList(
  //   Object.keys(config.lp).map((key) => {
  //     return config.lp[key];
  //   })
  // );

  // setConfigDMMLPList(
  //   Object.keys(config.dmmLP).map((key) => {
  //     return config.dmmLP[key];
  //   })
  // );

  // setConfigLPList(
  //   Object.keys(config.lp)
  //     .map((key) => {
  //       return config.lp[key];
  //     })
  //     .concat(
  //       Object.keys(config.dmmLP).map((key) => {
  //         return config.dmmLP[key];
  //       })
  //     )
  // );

  // getAllLpInfo();

  // const handleWalletUpdated = () => {
  // getAllLpInfo();
  // if (removeLPModalRef.current) {
  //   (removeLPModalRef.current as any).close(); // Close modal
  // }
  // };

  // window.addEventListener('wallet-updated', handleWalletUpdated);

  // return () => {
  //   window.removeEventListener('wallet-updated', handleWalletUpdated);
  // };
  // }, []);

  useEffect(() => {
    // if (walletApi.account.current && ethereum.web3)
    // getAllLpInfo();

    if (walletApi.hasAddress && isReady && walletApi.isConnected) {
      setConfigDMMLPList(
        Object.keys(config.dmmLP).map((key) => {
          return config.dmmLP[key];
        })
      );
      setConfigLPList(
        Object.keys(config.lp)
          .filter((key) => config.lp[key]?.address !== undefined)
          .map((key) => {
            return config.lp[key];
          })
          .concat(
            Object.keys(config.dmmLP).map((key) => {
              return config.dmmLP[key];
            })
          )
      );
    } else {
      setConfigDMMLPList([]);
      setConfigLPList([]);
    }
  }, [walletApi.hasAddress, walletApi.isConnected, isReady]);

  useEffect(() => {
    if (walletApi.hasAddress && configLPList.length > 0 && isReady) {
      getAllLpInfo();
    } else {
      setLpTokens([]);
      setJoinCounter(0);
      setIsLoadFinish(false);
    }
  }, [configLPList, triggerLp]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTriggerLp(!triggerLp);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [triggerLp]);

  // useEffect(() => {
  //   console.log("setConfigLPList configLPList", configLPList);
  //   console.log("setConfigLPList configDMMLPList", configDMMLPList);
  //   setConfigLPList(configLPList.concat(configDMMLPList));
  // }, [configLPList, configDMMLPList]);

  return (
    <div className="md:container p-0">
      <div className="xl:ml-32 md:flex md:flex-col xl:max-w-[867px]">
        <PageHeader
          title="Liquidity Pools"
          subTitle={`You have joined ${joinCounter} from ${lpTokens.length} Liquidity Pools`}
        />
        <div className="md:mb-2 px-4 md:px-0 md:container md:mx-auto items-center justify-center">
          {isLoadFinish && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-5 animate__animated animate__fadeInUp">
              {lpTokens.map((value, index) => (
                <div key={index} className="bg-cover border-none">
                  <LiquidityCardLp
                    // id={value.sortOrder}
                    // ref={`liquidityExpansionPanel${value.id}`}
                    lpToken={value}
                    isProcessing={value.isRemovingStatus}
                    onClickRemove={removeButtonClick}
                    onClickAddToken={addToken}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <RemoveModal ref={removeLPModalRef} onConfirmRemove={onConfirmRemove} />
    </div>
  );
};

export default LiquidityPools;
