import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/pageHeader";
import Button from "../../components/button";
import AmountInput from "../../components/amountInput";
import Table from "../../components/table";
import TableRow from "../../components/table/row";
import ToolTip from "../../components/toolTip";
import { Token } from "../../interfaces/token";
import { durationTime, lpType } from "../../constants";
import config from "../../config";
import { useWallet } from "../../contexts/walletContext";
import { useTokenHelpers } from "../../hooks/useTokenHelpers";
import BigNumber from "bignumber.js";
import { useEthereum } from "../../contexts/etherruemContext";
import useLiquidity from "../../hooks/useLiquidityHelpers";
import useSwapHelpers from "../../hooks/useSwapHelpers";
import { useNotification } from "../../contexts/notificationContext";
import { formatNumberWithMinMaxDigits } from "../../utils/filter";
import { useLocation, useNavigate } from "react-router-dom";
import AddModal from "../../components/liquidity/addModal";
import { useSlippageState } from "../../contexts/slippageContext";
import { addMinute } from "../../utils/datetime";
import useErrorHelpers from "../../hooks/useErrorHelpers";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";
import Web3 from "web3";

const AddLiquidity = () => {
  const [tokenA, setTokenA] = useState<Token>();
  const [tokenB, setTokenB] = useState<Token>();
  const [tokenABalance, setTokenABalance] = useState<string>("0.0");
  const [tokenBBalance, setTokenBBalance] = useState<string>("0.0");
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
  const [supplyAmountMaxToToken, setSupplyAmountMaxToToken] = useState<
    number | null
  >(null);
  const [supplyAmountTokenA, setSupplyAmountTokenA] = useState<string>();
  const [supplyAmountTokenB, setSupplyAmountTokenB] = useState<string>();
  const [lpContractName, setLpContractName] = useState<string | null>(null);
  const [displayBalanceTokenA, setDisplayBalanceTokenA] = useState("0.00");
  const [displayBalanceTokenB, setDisplayBalanceTokenB] = useState("0.00");
  const [submitButtonState, setSubmitButtonState] = useState("");
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [shareOfPool, setShareOfPool] = useState("0.00");
  const [tokenAPerTokenB, setTokenAPerTokenB] = useState("0.00");
  const [tokenBPerTokenA, setTokenBPerTokenA] = useState("0.00");
  const [currentTokenAPerTokenB, setCurrentTokenAPerTokenB] = useState("0.00");
  const [currentTokenA, setCurrentTokenA] = useState("0.00");
  const [currentTokenB, setCurrentTokenB] = useState("0.00");
  const [addingLPAmount, setAddingLPAmount] = useState("0.0000");
  const [lpAmountInWallet, setLpAmountInWallet] = useState("0.0000");
  const [currentPoolShare, setCurrentPoolShare] = useState("0.00");
  const [amplifier, setAmplifier] = useState<string>("");
  const [updatePriceTimer, setUpdatePriceTimer] = useState<number | null>(null);
  const [txHash, setTxHash] = useState<string>("");

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const ethereum = useEthereum();
  // const wallet = useWallet();
  const { checkAllowanceToSpender, approveTokenToSpender } = useTokenHelpers();
  const {
    getToken0AndToken1InLp,
    getTotalPoolShare,
    getReserveInPool,
    addLiquidityETHOnAMM,
    addLiquidityOnDMM,
    addLiquidityOnAMM,
  } = useLiquidity();
  const { getAMPBPS } = useSwapHelpers();
  const { setNotify } = useNotification();
  const { slippage, trxDeadline } = useSlippageState();
  const { getErrorReason } = useErrorHelpers();
  const { walletApi } = useUnifiedWallet();

  const isTokensSelected = useMemo(() => {
    return !!tokenA?.symbol && !!tokenB?.symbol;
  }, [tokenA, tokenB]);

  const isInvalid = useMemo(() => {
    return (
      !isTokensSelected ||
      submitButtonLoading ||
      [
        "enterAmount",
        "insufficientBalanceTokenA",
        "insufficientBalanceTokenB",
      ].includes(submitButtonState) ||
      !submitButtonState
    );
  }, [isTokensSelected, submitButtonLoading, submitButtonState]);

  const submitButtonText = useMemo(() => {
    if (!isTokensSelected) {
      return "Select token";
    }

    switch (submitButtonState) {
      case "approveTokenA":
        return `Approve ${tokenA?.name}`;
      case "approveTokenB":
        return `Approve ${tokenB?.name}`;
      case "enterAmount":
        return "Enter amount";
      case "insufficientBalanceTokenA":
        return `Insufficient ${tokenA?.name} balance`;
      case "insufficientBalanceTokenB":
        return `Insufficient ${tokenB?.name} balance`;
      case "supply":
        return "Supply liquidity";
      default:
        return "Processing...";
    }
  }, [isTokensSelected, submitButtonState, tokenA, tokenB]);

  useEffect(() => {
    mapToken();
    // mapTokenList();
  }, [location]);

  // useEffect(() => {
  //   calculateNewPoolShare();
  //   handleSubmitButtonState();
  // }, [tokenA, tokenB, supplyAmountTokenA, supplyAmountTokenB]);

  useEffect(() => {
    const init = async () => {
      if (
        walletApi.hasAddress &&
        ethereum?.contracts?.directExchange &&
        ethereum?.web3
      ) {
        await getBalances();
      } else {
        setDisplayBalanceTokenA("0.00");
        setDisplayBalanceTokenB("0.00");
      }
    };
    const intervalId = setInterval(() => {
      init();
    }, 5000);
    init();

    return () => clearInterval(intervalId);
  }, [walletApi.hasAddress, ethereum, tokenA, tokenB]);

  useEffect(() => {
    if (isTokensSelected && tokenA && tokenB) {
      updateNewPoolShare();
    }
    handleSubmitButtonState();
  }, [
    tokenA,
    tokenB,
    supplyAmountTokenA,
    supplyAmountTokenB,
    isTokensSelected,
  ]);

  const getLPConfig = (
    tokenASymbol = "",
    tokenBSymbol = "",
    fallbackEmptyObject = true
  ) => {
    const emptyObj = {
      lpAddress: null,
      lpName: null,
      lpImage: null,
      token0: null,
      token1: null,
    };

    const lpConfig: any = Object.assign({}, config.lp, config.dmmLP);

    if (!tokenASymbol) return null;

    const symbolA = tokenASymbol.toUpperCase();
    const symbolB = tokenBSymbol.toUpperCase();
    const lpAB = `${symbolA}_${symbolB}`;
    const lpBA = `${symbolB}_${symbolA}`;

    if (!!lpConfig[lpAB]) {
      setLpContractName(lpAB);
      return lpConfig[lpAB];
    }

    if (!!lpConfig[lpBA]) {
      setLpContractName(lpBA);
      return lpConfig[lpBA];
    }

    // lpContractName = null

    return fallbackEmptyObject ? emptyObj : null;
  };

  const lpConfigSelected = useMemo(() => {
    return getLPConfig(tokenA?.symbol, tokenB?.symbol);
  }, [tokenA, tokenB, tokenA?.symbol, tokenB?.symbol]);

  const isDMMType = useMemo(() => {
    if (!lpConfigSelected) return false;
    return lpConfigSelected.lpType === lpType.DMM_TYPE;
  }, [lpConfigSelected]);

  const mapTokenList = () => {
    // const configList = [
    //   config.token.EVRY_TOKEN,
    //   config.token.BNB_TOKEN,
    //   config.token.BUSD_TOKEN,
    //   config.token.XLM_TOKEN,
    // ];
    // tokenListA = configList.filter(
    //   ({ symbol }) => `${tokenB.symbol}` !== symbol
    // )
    // tokenListB = configList.filter(
    //   ({ symbol }) => `${tokenA.symbol}` !== symbol
    // )
  };

  const getTokenConfig = (name = "") => {
    const { token } = config;
    const tokenName = name.toUpperCase();

    return token[tokenName] || null;
  };

  const mapToken = () => {
    const queryParams = new URLSearchParams(location.search);
    const tokenAParam = queryParams.get("tokenA") || ""; // Defaults to empty string if not found
    const tokenBParam = queryParams.get("tokenB") || "";
    // const { tokenA = "", tokenB = "" } = $route.query;

    const configTokenA = getTokenConfig(tokenAParam);
    if (!!configTokenA) setTokenA(configTokenA);

    const configTokenB = getTokenConfig(tokenBParam);
    if (!!configTokenB) setTokenB(configTokenB);
  };

  useEffect(() => {
    if (isTokensSelected && ethereum.contracts) {
      start();
    }
  }, [isTokensSelected, ethereum.contracts]);

  const clearInputAmount = () => {
    setSupplyAmountTokenA("");
    setSupplyAmountTokenB("");
    setSubmitButtonState("enterAmount");
  };

  const isApproveToken = (tokenSymbol: string) => {
    if (tokenSymbol === "BNB_TOKEN") return true;
    const ownerAddress = walletApi.account.current ?? "";
    let spenderAddress = "";
    if (
      lpConfigSelected?.lpType &&
      lpConfigSelected.lpType === lpType.AMM_TYPE
    ) {
      spenderAddress = config.ammRouterContractAddress ?? "";
    } else if (
      lpConfigSelected?.lpType &&
      lpConfigSelected.lpType === lpType.DMM_TYPE
    ) {
      spenderAddress = config.dmmRouterContractAddress ?? "";
    }

    console.log("tokenSymbol", tokenSymbol);

    console.log("ethereum", ethereum);

    if (
      ethereum === undefined ||
      ethereum.contracts === undefined ||
      !ethereum.contracts ||
      ethereum.contracts.tokens === undefined
    ) {
      return false;
    }
    return checkAllowanceToSpender(tokenSymbol, ownerAddress, spenderAddress);
  };

  const checkApprove = async () => {
    if (supplyAmountTokenA || supplyAmountTokenB) {
      setSubmitButtonState("");
    }

    if (tokenA === undefined || tokenB === undefined) {
      return false;
    }
    let isApproveA = false;
    try {
      isApproveA = await isApproveToken(tokenA?.code ?? "");
    } catch (err) {
      console.log("error isApproveTokenA", err);
      isApproveA = false;
    }
    if (!isApproveA) {
      setSubmitButtonState("approveTokenA");
      return false;
    }
    let isApproveB = false;
    try {
      isApproveB = await isApproveToken(tokenB?.code ?? "");
    } catch (err) {
      console.log("error isApproveTokenB", err);
      isApproveB = false;
    }
    if (!isApproveB) {
      setSubmitButtonState("approveTokenB");
      return false;
    }

    return true;
  };

  const checkAmount = () => {
    if (!supplyAmountTokenA || !supplyAmountTokenB) {
      setSubmitButtonState("enterAmount");
      return false;
    }

    const supplyA = BigNumber(toPrecision(supplyAmountTokenA));
    const balanceTokenA = BigNumber(
      ethereum.web3?.utils.fromWei(parseFloat(tokenABalance), "ether") ?? "0"
    );
    if (supplyA.isGreaterThan(balanceTokenA)) {
      setSubmitButtonState("insufficientBalanceTokenA");
      return false;
    }

    const supplyB = BigNumber(toPrecision(supplyAmountTokenB, 16));

    const balanceTokenB = BigNumber(
      ethereum.web3?.utils.fromWei(parseFloat(tokenBBalance), "ether") ?? "0"
    );
    console.log("halo :", supplyB.toString(), balanceTokenB.toString());
    if (supplyB.isGreaterThan(balanceTokenB)) {
      setSubmitButtonState("insufficientBalanceTokenB");
      return false;
    }

    return true;
  };

  const handleSubmitButtonState = async () => {
    const x = await checkApprove();
    if (!x) return;
    if (!checkAmount()) return;

    setSubmitButtonState("supply");
  };

  const calculateNewPoolShare = (
    amountA: number,
    amountB: number,
    reservesA: number,
    reservesB: number,
    totalSupply: string
  ) => {
    if ((!amountA && !amountB) || (isNaN(amountA) && isNaN(amountB))) {
      setShareOfPool("0.00");
      setTokenAPerTokenB("0.00");
      setTokenBPerTokenA("0.00");
      setAddingLPAmount("0.00");

      return;
    }

    const amountABN = BigNumber(amountA);
    const amountBBN = BigNumber(amountB);
    const reservesABN = BigNumber(reservesA);
    const reservesBBN = BigNumber(reservesB);
    const totalSupplyBN = BigNumber(totalSupply);

    const liquidity = BigNumber(
      Math.min(
        parseFloat(
          amountABN.multipliedBy(totalSupplyBN).div(reservesABN).toString()
        ),
        parseFloat(
          amountBBN.multipliedBy(totalSupplyBN).div(reservesBBN).toString()
        )
      )
    );

    const newPercentOfPoolshare = liquidity
      .div(totalSupplyBN.plus(liquidity))
      .multipliedBy(100);
    const newReserveA = amountABN.plus(reservesABN);
    const newReserveB = amountBBN.plus(reservesBBN);

    setShareOfPool(newPercentOfPoolshare.toNumber().toFixed(2));
    setTokenBPerTokenA(newReserveB.div(newReserveA).toString());
    setTokenAPerTokenB(newReserveA.div(newReserveB).toString());
    setAddingLPAmount(liquidity.toString(10));
  };

  const updateNewPoolShare = async () => {
    if (ethereum.web3 && ethereum.contracts && lpContractName) {
      const reserves = await ethereum.contracts.tokens[lpContractName].methods
        .getReserves()
        .call();

      const totalSupply = await ethereum.contracts.tokens[
        lpContractName
      ].methods
        .totalSupply()
        .call();

      const reserveA = parseFloat(
        ethereum.web3.utils.fromWei(reserves._reserve0, "ether")
      );
      const reserveB = parseFloat(
        ethereum.web3.utils.fromWei(reserves._reserve1, "ether")
      );

      let newReserveA = null;
      let newReserveB = null;

      const tokens = await getToken0AndToken1InLp(lpContractName);
      if (
        tokenA?.address?.toUpperCase() ===
        tokens?.token0?.address?.toUpperCase()
      ) {
        newReserveA = reserveA;
        newReserveB = reserveB;
      } else {
        newReserveA = reserveB;
        newReserveB = reserveA;
      }

      const amountA = parseFloat(toPrecision(supplyAmountTokenA ?? "0"));
      const amountB = parseFloat(toPrecision(supplyAmountTokenB ?? "0"));

      calculateNewPoolShare(
        isNaN(amountA) ? 0 : amountA,
        isNaN(amountB) ? 0 : amountB,
        newReserveA,
        newReserveB,
        ethereum.web3.utils.fromWei(totalSupply, "ether")
      );
    }
  };

  const getAmplifier = async () => {
    if (lpConfigSelected.lpType === lpType.DMM_TYPE && lpContractName) {
      const amplifierResult = await getAMPBPS(lpContractName);
      setAmplifier(BigNumber(amplifierResult).div(10000).toString(10));
    }
  };

  const amountChanged = async (inputToken: string, amount?: string) => {
    if (!isTokensSelected || !lpContractName || !ethereum.web3) return;
    const reserves = await ethereum.contracts.tokens[lpContractName].methods
      .getReserves()
      .call();

    const reserveA = parseFloat(
      ethereum.web3?.utils.fromWei(reserves._reserve0, "ether")
    );
    const reserveB = parseFloat(
      ethereum.web3?.utils.fromWei(reserves._reserve1, "ether")
    );

    let newReserveA = null;
    let newReserveB = null;

    const tokens = await getToken0AndToken1InLp(lpContractName);
    if (
      tokenA?.address?.toUpperCase() === tokens?.token0?.address?.toUpperCase()
    ) {
      newReserveA = reserveA;
      newReserveB = reserveB;
    } else {
      newReserveA = reserveB;
      newReserveB = reserveA;
    }

    if (inputToken === "tokenA") {
      const amountToUse = amount ? amount : supplyAmountTokenA;
      const supply = !!amountToUse
        ? BigNumber(toPrecision(amountToUse))
            .multipliedBy(BigNumber(newReserveB))
            .div(BigNumber(newReserveA))
            .toFixed(16)
        : "";
      setSupplyAmountTokenB(supply);
    } else {
      const amountToUse = amount ? amount : supplyAmountTokenB;
      const supply = !!amountToUse
        ? BigNumber(toPrecision(amountToUse))
            .multipliedBy(BigNumber(newReserveA))
            .div(BigNumber(newReserveB))
            .toFixed(16)
        : "";
      setSupplyAmountTokenA(supply);
    }

    getAmplifier();

    handleSubmitButtonState();
  };

  const updateAmountTokenA = (supplyAmount: string | null) => {
    if (supplyAmountTokenA === supplyAmount) return;

    clearInputAmount();

    handleSubmitButtonState();

    if (!supplyAmount || isNaN(parseFloat(supplyAmount))) return;

    setSupplyAmountTokenA(supplyAmount);

    if (!parseFloat(supplyAmount)) return;

    amountChanged("tokenA", supplyAmount);
  };

  const updateAmountTokenB = (supplyAmount: string | null) => {
    if (supplyAmountTokenB === supplyAmount) return;
    clearInputAmount();

    handleSubmitButtonState();

    if (!supplyAmount || isNaN(parseFloat(supplyAmount))) return;

    setSupplyAmountTokenB(supplyAmount);

    if (!parseFloat(supplyAmount)) return;

    amountChanged("tokenB", supplyAmount);
  };

  const toPrecision = (value: string, digit = 18) => {
    return BigNumber(value).toFixed(digit, 1).toString();
  };

  const tokenBalanceOf = async (tokenSymbol: string) => {
    try {
      if (tokenSymbol === "BNB_TOKEN") {
        return await ethereum?.web3?.eth.getBalance(
          walletApi.account.current ?? ""
        );
      }
      return await ethereum.contracts.tokens[`${tokenSymbol}`].methods
        .balanceOf(walletApi.account.current ?? "")
        .call();
    } catch {}
  };

  const getBalances = async () => {
    if (!ethereum?.web3) return;
    if (tokenA?.code && tokenA?.code !== "") {
      const balance = await tokenBalanceOf(tokenA?.code);
      if (!balance) {
        setIsInsufficientBalance(true);
      } else {
        setTokenABalance(balance);
        setDisplayBalanceTokenA(
          toPrecision(
            ethereum.web3.utils.fromWei(balance, "ether").toLocaleString(),
            16
          )
        );
      }
    }

    console.log("tokenB", tokenB);
    if (tokenB?.code && tokenB.code !== "") {
      const balance = await tokenBalanceOf(tokenB?.code);
      console.log("balanceB", balance);
      if (!balance) {
        setIsInsufficientBalance(true);
        return;
      } else {
        setTokenBBalance(balance);
        setDisplayBalanceTokenB(
          toPrecision(
            ethereum.web3.utils.fromWei(balance, "ether").toLocaleString(),
            16
          )
        );
      }
    }
  };

  const getBalanceOfLP = async (lpName: string, address: string) => {
    if (ethereum.contracts != null && ethereum.contracts.tokens[lpName]) {
      return await ethereum.contracts.tokens[lpName].methods
        .balanceOf(address)
        .call();
    }
    return "0";
  };

  const getCurrentTotalPoolShare = async () => {
    if (walletApi.account.current && lpContractName) {
      const address = walletApi.account.current ?? "";
      const poolShare = await getTotalPoolShare(lpContractName, address);
      const reservePool = await getReserveInPool(lpContractName);
      const lpBalance = BigNumber(
        Web3.utils.fromWei(poolShare.lpBalance, "ether")
      );

      if (!lpBalance.isZero()) {
        const totalSupply = BigNumber(
          Web3.utils.fromWei(poolShare.totalSupply, "ether")
        );

        const reserveA = BigNumber(
          Web3.utils.fromWei(reservePool._reserve0, "ether")
        );
        const reserveB = BigNumber(
          Web3.utils.fromWei(reservePool._reserve1, "ether")
        );
        let newReserveA = BigNumber("0");
        let newReserveB = BigNumber("0");

        const tokens = await getToken0AndToken1InLp(lpContractName);
        if (
          tokenA?.address?.toUpperCase() ===
          tokens?.token0?.address?.toUpperCase()
        ) {
          newReserveA = reserveA;
          newReserveB = reserveB;
        } else {
          newReserveA = reserveB;
          newReserveB = reserveA;
        }

        setCurrentTokenAPerTokenB(
          BigNumber(newReserveA).div(BigNumber(newReserveB)).toString(10)
        );

        setCurrentTokenA(
          formatNumberWithMinMaxDigits(
            parseFloat(
              newReserveA.multipliedBy(lpBalance.div(totalSupply)).toString(10)
            ),
            4,
            8
          )
        );

        setCurrentTokenB(
          formatNumberWithMinMaxDigits(
            parseFloat(
              newReserveB.multipliedBy(lpBalance.div(totalSupply)).toString(10)
            ),
            4,
            8
          )
        );

        setCurrentPoolShare(
          lpBalance.div(totalSupply).multipliedBy(100).toString(10)
        );
      } else {
        setCurrentTokenAPerTokenB("0.00");
        setCurrentTokenA("0.00");
        setCurrentTokenB("0.00");
        setCurrentPoolShare("0.00");
      }

      setLpAmountInWallet(lpBalance.toString(10));
      getAmplifier();
    }
  };

  const start = async () => {
    if (!walletApi.account.current || !tokenA || !tokenB) {
      setTimeout(() => {
        start();
      }, 2000);
      return;
    }

    const balanceA = await tokenBalanceOf(tokenA?.code);
    const balanceB = await tokenBalanceOf(tokenB?.code);
    setTokenABalance(balanceA);
    setTokenBBalance(balanceB);

    getBalances();

    getCurrentTotalPoolShare();

    handleSubmitButtonState();
  };

  const approveToken = (tokenSymbol: string) => {
    const ownerAddress = walletApi.account.current ?? "";
    let spenderAddress = "";
    if (lpConfigSelected.lpType === lpType.AMM_TYPE) {
      spenderAddress = config.ammRouterContractAddress ?? "";
    } else if (lpConfigSelected.lpType === lpType.DMM_TYPE) {
      spenderAddress = config.dmmRouterContractAddress ?? "";
    }
    approveTokenToSpender(
      ethereum.contracts.tokens[`${tokenSymbol}`]._address,
      ownerAddress,
      spenderAddress,
      {
        onTransactionHash: (txHash) => {
          setSubmitButtonLoading(true);
          setSubmitButtonState("");

          const payload = {
            status: "processing",
            txHash,
          };
          setNotify(payload);
        },
        onReceipt: (result) => {
          setSubmitButtonLoading(false);
          handleSubmitButtonState();
          const payload = {
            status: "success",
            txHash: result.transactionHash,
          };
          setNotify(payload);
          start();
        },
        onError: (receipt: any) => {
          setSubmitButtonLoading(false);
          const payload = {
            status: "failed",
            txHash: receipt ? receipt.transactionHash : null,
            errorReason: receipt ? receipt.errorReason : null,
          };
          setNotify(payload);
        },
      }
    );
  };

  const submitAction = () => {
    if (submitButtonState === "approveTokenA" && tokenA?.code) {
      approveToken(tokenA?.code);
      return;
    }

    if (submitButtonState === "approveTokenB" && tokenB?.code) {
      approveToken(tokenB?.code);
      return;
    }

    if (submitButtonState === "supply") {
      setIsConfirmModalOpen(true);
    }
  };

  const backward = () => {
    navigate(-1);
  };

  const updateEntryLP = () => {
    // $bus.$emit(`update:lp-${lpConfigSelected.address}`)
  };

  const onFinishCallback = () => {
    setSubmitButtonLoading(false);
    setSubmitButtonState("supply");

    updateNewPoolShare();
    getCurrentTotalPoolShare();
    clearInputAmount();
    start();

    // wallet.updateBalance();
  };

  const addLiquidityPoolETH = async (
    ethAmount: string,
    tokenAddress: string,
    tokenAmount: string,
    minTokenAmount: string,
    amountEthMin: string,
    deadline: string
  ) => {
    await addLiquidityETHOnAMM(
      ethAmount,
      tokenAddress,
      tokenAmount,
      minTokenAmount,
      amountEthMin,
      walletApi.account.current ?? "",
      deadline,
      {
        onTransactionHash: (txHash: any) => {
          const payload = {
            status: "processing",
            txHash,
            duration: durationTime.MIDDLE_TIME,
          };
          setNotify(payload);

          setTxHash(txHash);
        },
        onReceipt: (receipt: any) => {
          const payload = {
            status: receipt.status ? "success" : "failed",
            txHash: receipt.transactionHash,
            duration: durationTime.MIDDLE_TIME,
          };
          setNotify(payload);

          updateEntryLP();
          onFinishCallback();
        },
        onError: async (e: any) => {
          const payload = {
            status: "failed",
            errorReason: e?.shortMessage || "Transaction failed",
            txHash: txHash,
            duration: durationTime.MIDDLE_TIME,
          };
          setNotify(payload);

          setTxHash(txHash);

          onFinishCallback();
        },
      }
    );
  };

  const addLiquidityPoolOnAMM = async (
    amountTokenA: string,
    amountTokenB: string,
    amountAMin: string,
    amountBMin: string
  ) => {
    if (!ethereum.web3 || !tokenA || !tokenB) return;
    await addLiquidityOnAMM(
      tokenA.address ?? "",
      tokenB.address ?? "",
      amountTokenA,
      amountTokenB,
      amountAMin,
      amountBMin,
      walletApi.account.current ?? "",
      addMinute(parseInt(trxDeadline)),
      {
        onTransactionHash: (txHash: any) => {
          const payload = {
            status: "processing",
            txHash,
            duration: durationTime.LONG_TIME,
          };
          setNotify(payload);

          setTxHash(txHash);
        },
        onReceipt: (receipt: any) => {
          const payload = {
            status: receipt.status ? "success" : "failed",
            txHash: receipt.transactionHash,
            duration: durationTime.LONG_TIME,
            errorReason: receipt ? receipt.errorReason : null,
          };
          setNotify(payload);

          updateEntryLP();
          onFinishCallback();
        },
        onError: async (e: any) => {
          const reason = await getErrorReason(txHash);

          const payload = {
            status: "failed",
            errorReason: reason,
            txHash: txHash,
            duration: durationTime.LONG_TIME,
          };
          setNotify(payload);

          setTxHash(txHash);

          onFinishCallback();
        },
      }
    );
  };

  const addLiquidityPoolOnDMM = async (
    amountTokenA: string,
    amountTokenB: string,
    amountAMin: string,
    amountBMin: string
  ) => {
    if (!ethereum.web3 || !tokenA || !tokenB) return;
    await addLiquidityOnDMM(
      tokenA.address ?? "",
      tokenB.address ?? "",
      lpConfigSelected.address,
      amountTokenA,
      amountTokenB,
      amountAMin,
      amountBMin,
      walletApi.account.current ?? "",
      addMinute(parseInt(trxDeadline)),
      {
        onTransactionHash: (txHash: any) => {
          const payload = {
            status: "processing",
            txHash,
            duration: durationTime.LONG_TIME,
          };
          setNotify(payload);

          setTxHash(txHash);
        },
        onReceipt: (receipt: any) => {
          const payload = {
            status: receipt.status ? "success" : "failed",
            txHash: receipt.transactionHash,
            duration: durationTime.LONG_TIME,
            errorReason: receipt ? receipt.errorReason : null,
          };
          setNotify(payload);

          updateEntryLP();
          onFinishCallback();
        },
        onError: async (e: any) => {
          const payload = {
            status: "failed",
            errorReason: e?.shortMessage || "Transaction failed",
            txHash: txHash,
            duration: durationTime.LONG_TIME,
          };
          setNotify(payload);

          setTxHash(txHash);

          onFinishCallback();
        },
      }
    );
  };

  const confirmToAddLP = async () => {
    if (
      !lpContractName ||
      !supplyAmountTokenA ||
      !supplyAmountTokenB ||
      !tokenA ||
      !tokenB
    )
      return;

    const multiplier = BigNumber(slippage).div(100);

    const amountA = toPrecision(supplyAmountTokenA);
    const amountB = toPrecision(supplyAmountTokenB);

    console.log("amountA", amountA);
    console.log("amountB", amountB);

    const amountTokenA = Web3.utils.toWei(
      BigNumber(amountA).toString(10),
      "ether"
    );
    const amountTokenB = Web3.utils.toWei(
      BigNumber(amountB).toString(10),
      "ether"
    );

    console.log("amountTokenA", amountTokenA);
    console.log("amountTokenB", amountTokenB);

    console.log("multiplier", multiplier);

    const slippageAmountA = BigNumber(amountA).times(multiplier).toString(10);

    const slippageAmountB = BigNumber(amountB).times(multiplier).toString(10);

    console.log("slippageAmountA", slippageAmountA);
    console.log("slippageAmountB", slippageAmountB);

    const amountAMin = Web3.utils.toWei(
      BigNumber(amountA).minus(slippageAmountA).toString(10),
      "ether"
    );
    const amountBMin = Web3.utils.toWei(
      BigNumber(amountB).minus(slippageAmountB).toString(10),
      "ether"
    );

    console.log("amountAMin", amountAMin);
    console.log("amountBMin", amountBMin);

    try {
      // A -> ETH
      if (
        tokenA?.address?.toUpperCase() ===
        config.token.BNB_TOKEN.address?.toUpperCase()
      ) {
        await addLiquidityPoolETH(
          amountTokenA,
          tokenB.address ?? "",
          amountTokenB,
          amountBMin,
          amountAMin,
          addMinute(parseInt(trxDeadline))
        );
      } else if (
        tokenB.address?.toUpperCase() ===
        config.token.BNB_TOKEN.address?.toUpperCase()
      ) {
        await addLiquidityPoolETH(
          amountTokenB,
          tokenA.address ?? "",
          amountTokenA,
          amountAMin,
          amountBMin,
          addMinute(parseInt(trxDeadline))
        );
      } else if (lpConfigSelected.lpType === lpType.DMM_TYPE) {
        await addLiquidityPoolOnDMM(
          amountTokenA,
          amountTokenB,
          amountAMin,
          amountBMin
        );
      } else if (lpConfigSelected.lpType === lpType.AMM_TYPE) {
        await addLiquidityPoolOnAMM(
          amountTokenA,
          amountTokenB,
          amountAMin,
          amountBMin
        );
      } else {
        console.log("Unsupport other LP");
      }
    } catch (e) {
      console.log("error", e);
    }
    setIsConfirmModalOpen(false);
  };

  const handleClickConfirm = () => {
    setSubmitButtonLoading(true);
    setSubmitButtonState("");
    confirmToAddLP();
  };

  return (
    <div className="md:container p-0 md:mb-24">
      <div className="page-wrapper mx-auto md:flex md:flex-col xl:max-w-[867px]">
        <PageHeader
          title="Add Liquidity"
          subTitle="Add pair of the liquidity"
          isTop={true}
        >
          <Button
            type="text"
            className="flex items-center text-evry-primary text-xs border-none px-0"
            paddingInside="px-0"
            onClick={backward}
          >
            <img
              src={require("/src/assets/icons/general/arrow_back.svg").default}
              className="mr-2"
              alt="back"
            />
            <div className="whitespace-nowrap text-velo-wisp-pink">
              Back to Liquidity Pools
            </div>
          </Button>
        </PageHeader>
        <div className="px-4 md:px-0">
          <div className="flex flex-col md:flex-row items-center justify-between relative">
            <AmountInput
              className="w-full md:w-[400px] 2xl:w-full col-span-5"
              testId="pool_token_in"
              token={tokenA}
              amount={supplyAmountTokenA}
              balance={displayBalanceTokenA}
              isInsufficient={isInsufficientBalance}
              onUpdateAmount={updateAmountTokenA}
              extraText={
                tokenA?.name === "USDT" && tokenB?.name === "BUSD"
                  ? "Minimum is 0.1"
                  : ""
              }
            />
            <div className="relative m-2 md:pt-6 mx-[17px]">
              <img
                className="min-w-[32px] h-8"
                src={require("/src/assets/icons/pool/pool.svg").default}
                alt="pool"
              />
            </div>
            <AmountInput
              className="w-full md:w-[400px] 2xl:w-full col-span-5"
              testId="pool_token_out"
              token={tokenB}
              amount={supplyAmountTokenB}
              balance={displayBalanceTokenB}
              isInsufficient={isInsufficientBalance}
              onUpdateAmount={updateAmountTokenB}
              extraText={
                tokenA?.name === "USDT" && tokenB?.name === "BUSD"
                  ? "Minimum is 0.1"
                  : ""
              }
            />
          </div>
        </div>
        <div className="mt-6 px-4 md:px-0 md:flex md:gap-2">
          <Table
            position="top"
            className="flex-1 text-xs p-2 pb-2 mb-2 md:p-4 md:pb-4 md:mb-4"
          >
            <div className="text-velo-wisp-pink font-semibold mb-2">
              Price and pool share
            </div>
            <TableRow
              className="p-2 mb-2"
              leftSlot={
                <div
                  slot="left"
                  className="flex items-center text-velo-wisp-pink"
                >
                  <div className="flex items-center">
                    <img
                      src={tokenA?.image}
                      className="h-4"
                      alt={tokenA?.name}
                    />
                    <img
                      src={tokenB?.image}
                      className="h-4"
                      alt={tokenB?.name}
                    />
                    <div className="text-xs ml-1">
                      You'll get LP {tokenA?.name} / {tokenB?.name}
                    </div>
                  </div>
                </div>
              }
              rightSlot={
                <div slot="right" className="text-velo-wisp-pink">
                  {parseFloat(addingLPAmount).toFixed(4)}{" "}
                  {/* formatNumberWithMinMaxDigits(4, 8) equivalent */}
                </div>
              }
            ></TableRow>
            <TableRow
              className="p-2"
              leftSlot={
                <div
                  slot="left"
                  className="flex items-center text-velo-wisp-pink"
                >
                  Share of pool
                </div>
              }
              rightSlot={
                <div slot="right" className="text-velo-wisp-pink">
                  {shareOfPool}%
                </div>
              }
            ></TableRow>
          </Table>
          <Table
            position="top"
            className="flex-1 text-xs p-2 pb-2 mb-2 md:p-4 md:pb-4 md:mb-4"
          >
            <div className="text-velo-wisp-pink font-semibold mb-2">
              Liquidity pool token in your wallet
            </div>
            <TableRow
              className="p-2 mb-2"
              leftSlot={
                <div slot="left" className="flex items-center">
                  <img src={tokenA?.image} className="h-4" alt={tokenA?.name} />
                  <img src={tokenB?.image} className="h-4" alt={tokenB?.name} />
                  <div className="flex items-center text-xs ml-1">
                    <span className="text-velo-wisp-pink">Current LP</span>
                    <ToolTip
                      className="ml-2 w-3 h-3"
                      iconClass="leading-none text-[#2EADD4]"
                      message={`
                      <div class='flex flex-col'>
                          <div class='flex justify-between'>
                              <div>Pooled ${tokenA?.name}</div>
                              <div>${currentTokenA}</div>
                          </div>
                          <div class='flex justify-between'>
                              <div>Pooled ${tokenB?.name}</div>
                              <div>${currentTokenB}</div>
                          </div>
                      </div>
                    `}
                    />
                  </div>
                </div>
              }
              rightSlot={
                <div slot="right" className="text-velo-wisp-pink">
                  {parseFloat(lpAmountInWallet).toFixed(2)}{" "}
                  {/* formatNumberWith2Digits equivalent */}
                </div>
              }
            ></TableRow>
            <TableRow
              className="p-2"
              leftSlot={
                <div
                  slot="left"
                  className="flex items-center text-velo-wisp-pink"
                >
                  Your current share of pool
                </div>
              }
              rightSlot={
                <div slot="right" className="text-velo-wisp-pink">
                  {parseFloat(currentPoolShare).toFixed(2)}%
                </div>
              }
            ></TableRow>
          </Table>
        </div>
        <div className="w-full md:container flex mt-4 md:mt-6 px-4">
          <Button
            className="w-full md:w-[276px] uppercase mx-auto h-11 font-semibold tracking-wider md:col-start-3 md:col-span-2 border-none mb-4"
            // testId="pool_confirm_btn"
            disabled={isInvalid}
            onClick={submitAction}
          >
            {submitButtonText}
          </Button>
        </div>
        <div className="w-full md:container flex px-6">
          <Button
            type="text"
            className="flex items-center mx-auto text-evry-primary text-xs border-none px-0"
            paddingInside="px-0"
            onClick={backward}
          >
            <img
              src={
                require("/src/assets/icons/general/arrow_forward.svg").default
              }
              className="mr-2"
              alt="forward"
            />
            <div className="whitespace-nowrap text-velo-wisp-pink">
              Deposit now
            </div>
          </Button>
        </div>
      </div>
      <AddModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        // ref={confirmModal}
        lpToken={lpConfigSelected}
        tokenA={tokenA}
        tokenB={tokenB}
        lpAmount={addingLPAmount}
        supplyAmountTokenA={toPrecision(supplyAmountTokenA ?? "0")}
        supplyAmountTokenB={toPrecision(supplyAmountTokenB ?? "0")}
        tokenAPerTokenB={tokenAPerTokenB}
        tokenBPerTokenA={tokenBPerTokenA}
        shareOfPool={shareOfPool}
        isDMMType={isDMMType}
        amplifier={amplifier}
        onConfirm={handleClickConfirm}
      />
    </div>
  );
};

export default AddLiquidity;
