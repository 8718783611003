import BigNumber from "bignumber.js";
import config from "../config";
import { useWallet } from "../contexts/walletContext";
import { useEthereum } from "../contexts/etherruemContext";
import { useTokenHelpers } from "./useTokenHelpers";
import DirectExchange from "../../src/assets/abi/directExchange/DirectExchange.json";
import { useWriteContract } from "wagmi";

const useDirectExchangeHelpers = () => {
  const wallet = useWallet();
  const ethereum = useEthereum();
  const { getBalanceOf } = useTokenHelpers();
  const { writeContractAsync } = useWriteContract();

  const getAllExchangeInfo = async () => {
    const { directExchange } = config;

    const result = await directExchange.reduce(async (prev, cur, index) => {
      let acc: any = await prev;

      const response = await getExchangeInfo(index, cur);

      if (response) acc = [...acc, response];

      return acc;
    }, Promise.resolve([]));

    console.log("result getAllExchangeInfo", result);

    return result;
  };

  const getTokenRatio = async (contractAddress: string) => {
    try {
      if (ethereum.contracts != null) {
        var rate = BigNumber(
          await ethereum.contracts.directExchange[contractAddress].methods
            .tokenRatio()
            .call()
        );
        console.log("contractAddress", contractAddress, "rate", rate);
        return rate;
      }
    } catch (e) {
      console.log("Err: getTokenRatio", e);
      return null;
    }
  };

  const getPauseInfo = async (contractAddress: string) => {
    try {
      if (ethereum.contracts != null) {
        return await ethereum.contracts.directExchange[contractAddress].methods
          .paused()
          .call();
      }
    } catch (e) {
      console.log("Err: getpaused", e);
      return null;
    }
  };

  const getExchangeInfo = async (index: number, data: any) => {
    const { directExchange } = config;
    const account = wallet.state.address;
    data = data || directExchange[index];
    if (!data) return {};
    const { contractAddress, destinationToken, originToken } = data;

    const promises = [
      getTokenRatio(contractAddress),
      getBalanceOf(destinationToken.code, contractAddress),
      getPauseInfo(contractAddress),
    ];

    const extendPromises = account
      ? [
          getBalanceOf(originToken.code, account),
          getBalanceOf(destinationToken.code, account),
        ]
      : [];

    const [
      tokenRatio,
      poolBalances,
      paused,
      originBalances,
      destinationBalances,
    ] = await Promise.all([...promises, ...extendPromises]);
    console.log(
      "address",
      directExchange[index].contractAddress,
      "tokenRatio",
      tokenRatio
    );
    const result = {
      poolBalances: poolBalances
        ? BigNumber(poolBalances).div(1e18)
        : BigNumber("0"),
      myOrigintokenBalance: originBalances
        ? BigNumber(originBalances)
        : BigNumber("0"),
      myDestinationTokenBalance: destinationBalances
        ? BigNumber(destinationBalances)
        : BigNumber("0"),
      exchangeRate: tokenRatio
        ? BigNumber(tokenRatio).div(1e18)
        : BigNumber("0"),
      paused,
    };
    return result;
  };

  const exchange = async (
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: any
  ) => {
    try {
      await writeContractAsync(
        // @ts-ignore
        {
          // abi: AutoRouter,
          // account: account.current,
          abi: DirectExchange,
          // @ts-ignore
          from: account,
          // @ts-ignore
          address: contractAddress,
          functionName: "exchange",
          args: [inputAmount.toFixed(0)],
        },
        {
          onSuccess(data: any, variables: any, context: any) {
            console.log("exchange: data", data);
            events.onReceipt({ transactionHash: data, status: true });
            return data;
          },
          onError: (error: any) => {
            events.onError(error);
            console.log("exchange: error", error);
          },
        }
      );
      // if (ethereum.contracts != null) {
      //   const receipt = await ethereum.contracts.directExchange[
      //     contractAddress
      //   ].methods
      //     .exchange(inputAmount.toString())
      //     .send({ from: account })
      //     .on("transactionHash", (txHash: any) => {
      //       events.onTransactionHash(txHash);
      //     })
      //     .on("receipt", (receipt: any) => {
      //       events.onReceipt(receipt);
      //     })
      //     .on("error", (err: any, receipt: any) => {
      //       events.onError(err, receipt);
      //       throw err;
      //     });

      //   return receipt;
      // }
    } catch (e) {
      console.log("Err: error exchange:", e);
      return null;
    }
  };

  return { getAllExchangeInfo, exchange };
};

export default useDirectExchangeHelpers;
