import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/pageHeader";
import Button from "../../components/button";
import config from "../../config";
import BigNumber from "bignumber.js";
import { useWallet } from "../../contexts/walletContext";
import useWalletHelpers from "../../hooks/useWalletHelpers";
import { digit, networkType } from "../../constants";
import {
  formatBigNumber,
  formatEther,
  formatNumberWithMinMaxDigits,
} from "../../utils/filter";
import { useTokenHelpers } from "../../hooks/useTokenHelpers";
import useDirectExchangeHelpers from "../../hooks/useDirectExchangeHelpers";
import { useMainConstant } from "../../contexts/mainContext";
import { useEthereum } from "../../contexts/etherruemContext";
import { useNotification } from "../../contexts/notificationContext";
import { toWei } from "../../utils/token";
import ConfirmDirectExchangeModal from "../../components/swap/confirmExchangModal";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";
import { DirectExchange as IDirectExchange } from "../../interfaces/config";
import AmountInput from "../../components/amountInput";
import useRouteHelpers from "../../hooks/useRouteHelpers";
import ButtonWrapper from "../../components/button/wrapper";
import Table from "../../components/table";
import TableRow from "../../components/table/row";
import ToolTip from "../../components/toolTip";
import { useSlippageState } from "../../contexts/slippageContext";
import MaterialIcon from "../../components/icon";
import ConfirmDirectOmniModal from "../../components/swap/confirmExchangOmni";

export interface DirectExchangeDetail {
  configExchange: IDirectExchange;
  poolBalances: BigNumber;
  myOriginTokenBalance: BigNumber;
  myDestinationTokenBalance: BigNumber;
  exchangeRate: BigNumber;
}

// const directExchangeDetail.configExchange = config.directExchange[0];

export interface Props {
  index: number;
}

const toPrecision = (value: string, digit: number = 8) => {
  return BigNumber(value).toFixed(digit, 1).toString();
};

const ExchangeOmni: React.FC<Props> = ({ index }) => {
  const [isPending, setIsPending] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [tokenABalance, setTokenABalance] = useState<string>("0.0");
  const [tokenBBalance, setTokenBBalance] = useState<string>("0.0");
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
  const [supplyAmountTokenA, setSupplyAmountTokenA] = useState<string>();
  const [supplyAmountTokenB, setSupplyAmountTokenB] = useState<string>();
  const [displayBalanceTokenA, setDisplayBalanceTokenA] = useState("0.00");
  const [displayBalanceTokenB, setDisplayBalanceTokenB] = useState("0.00");
  const [tradingFee, setTradingFee] = useState<string>("0.0");
  const [priceImpact, setPriceImpact] = useState<string>("0.0");
  const [priceImpactColorClass, setPriceImpactColorClass] =
    useState<string>("");
  const [amplifier, setAmplifier] = useState<string | null>(null);
  const [routeMap, setRouteMap] = useState<string[]>([]);
  const [routeMapText, setRouteMapText] = useState<string>("");
  const [tokenAPerTokenB, setTokenAPerTokenB] = useState<string>("0.0");
  const [tokenBPerTokenA, setTokenBPerTokenA] = useState<string>("0.0");
  const [lastTokenAPerBPrice, setLastTokenAPerBPrice] = useState<string>("");
  const [firstInputToken, setFirstInputToken] = useState<string>("");
  const [isCallSmartContractError, setIsCallSmartContractError] =
    useState<boolean>(false);
  const [minMaxTradingText, setMinMaxTradingText] = useState<string>("");

  // const wallet = useWallet();
  const { addTokenOnWallet } = useWalletHelpers();
  const { checkAllowanceToSpender } = useTokenHelpers();
  const { getAllExchangeInfo } = useDirectExchangeHelpers();
  const { approveTokenToSpender } = useTokenHelpers();
  const { slippage, trxDeadline } = useSlippageState();
  const {
    getRoute,
    findRoute,
    getEstimationAmount,
    getPairData,
    getDMMPoolAddresses,
  } = useRouteHelpers();
  const mainConstant = useMainConstant();
  const ethereum = useEthereum();
  const { setNotify } = useNotification();
  const { exchange } = useDirectExchangeHelpers();
  const { walletApi } = useUnifiedWallet();

  useEffect(() => {
    initialDirectExchangeDetail();
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);
    fetchData();

    return () => clearInterval(intervalId);
  }, [
    walletApi.account.current,
    ethereum?.contracts,
    ethereum?.contracts?.directExchange,
  ]);

  const initialDirectExchangeDetail = () => {
    return {
      configExchange: config.directExchange[index],
      poolBalances: BigNumber("0"),
      myOriginTokenBalance: BigNumber("0"),
      myDestinationTokenBalance: BigNumber("0"),
      exchangeRate: BigNumber("0"),
      pause: false,
    };
  };

  const [directExchangeDetail, setDirectExchangeDetail] =
    useState<DirectExchangeDetail>(initialDirectExchangeDetail());

  useEffect(() => {
    console.log("walletApi.hasaddress", walletApi.hasAddress);
    if (walletApi.hasAddress) getBalances();
  }, [walletApi.hasAddress, directExchangeDetail]);

  // useEffect(() => {
  //   console.log("walletApi.account.current", walletApi.account.current);
  //   if (walletApi.account.current) getBalances();
  // }, [
  //   walletApi.account.current,
  //   directExchangeDetail.configExchange.originToken,
  //   directExchangeDetail.configExchange.destinationToken,
  // ]);

  const isInputAmount = useMemo(() => {
    return !!supplyAmountTokenA && !!supplyAmountTokenB;
  }, [supplyAmountTokenA, supplyAmountTokenB]);

  const getPriceImpactClass = () => {
    if (parseFloat(priceImpact) > 15) return "text-red-1";
    else if (parseFloat(priceImpact) > 5) return "text-yellow-1";
    return "text-white";
  };

  const calculateMinReceive = useMemo(() => {
    if (supplyAmountTokenB && parseFloat(supplyAmountTokenB) > 0) {
      const amountTokenB = toPrecision(supplyAmountTokenB);
      const multiplier = BigNumber(slippage).div(100);
      const minAmountTokenB = BigNumber(amountTokenB).times(multiplier);
      return toPrecision(
        BigNumber(amountTokenB).minus(minAmountTokenB).toString()
      );
    }

    return "0";
  }, [supplyAmountTokenB, slippage]);

  const minMaxTradingAmount = useMemo(() => {
    if (supplyAmountTokenB && parseFloat(supplyAmountTokenB) > 0) {
      if (minMaxTradingText === "Min received") {
        return calculateMinReceive;
      } else if (supplyAmountTokenA) {
        const amountTokenA = toPrecision(supplyAmountTokenA);
        const maxAmountTokenA = BigNumber(amountTokenA).plus(
          BigNumber(amountTokenA).times(slippage).div(100)
        );
        return toPrecision(maxAmountTokenA.toString());
      }
    }

    return "0";
  }, [supplyAmountTokenB, minMaxTradingText, supplyAmountTokenA, slippage]);

  const fetchData = async () => {
    // const isValidChain = await walletApi.isValidChain();
    // console.log("fetchData isValidChain", isValidChain);
    // if (!isValidChain) {
    //   setDirectExchangeDetail(initialDirectExchangeDetail());
    //   return;
    // }
    checkStatus();
    const result: any = await getAllExchangeInfo();
    setDirectExchangeDetail((prev) => {
      return { ...prev, ...result[1] };
    });
    // if (result.paused) {
    //   $refs?.pausedModal?.open();
    // } else {
    //   $refs?.pausedModal?.close();
    // }
  };

  const checkStatus = async () => {
    const account = walletApi.account.current;
    if (!account) return;
    try {
      const result = await checkAllowanceToSpender(
        directExchangeDetail.configExchange.originToken.code ?? "",
        account,
        directExchangeDetail.configExchange.contractAddress ?? ""
      );
      setIsApproved(result);
    } catch (err) {
      console.error(err);
    }
  };

  const addToken = () => {
    const { address, image, name } =
      directExchangeDetail.configExchange.originToken;

    addTokenOnWallet(
      address ?? "",
      name,
      image ?? "",
      digit.TOKEN_DIGIT,
      networkType.ERC20,
      {
        onReceipt: () => console.log("added!"),
        onError: (error) => console.log(error),
      }
    );
  };

  const tokenBalanceOf = async (tokenSymbol: string) => {
    try {
      if (tokenSymbol === "BNB_TOKEN" && walletApi.account.current) {
        return await ethereum.web3?.eth.getBalance(walletApi.account.current);
      }
      return await ethereum.contracts.tokens[`${tokenSymbol}`].methods
        .balanceOf(walletApi.account.current)
        .call();
    } catch {
      console.error("tokenBalanceOf", tokenSymbol);
    }
  };

  const getBalances = async () => {
    try {
      if (
        directExchangeDetail.configExchange.originToken &&
        directExchangeDetail.configExchange.originToken?.symbol &&
        directExchangeDetail.configExchange.originToken?.symbol !== "" &&
        ethereum?.web3
      ) {
        // tokenA.balance = await tokenBalanceOf(tokenA.symbol)
        const balance = await tokenBalanceOf(
          directExchangeDetail.configExchange.originToken.code
        );
        if (!balance) return;

        setDisplayBalanceTokenA(
          ethereum?.web3.utils.fromWei(balance, "ether").toLocaleString()
        );
      }

      if (
        directExchangeDetail.configExchange.destinationToken &&
        directExchangeDetail.configExchange.destinationToken?.symbol &&
        directExchangeDetail.configExchange.destinationToken?.symbol !== "" &&
        ethereum?.web3
      ) {
        // tokenB.balance = await tokenBalanceOf(tokenB.symbol)
        const balance = await tokenBalanceOf(
          directExchangeDetail.configExchange.destinationToken.code
        );
        setDisplayBalanceTokenB(
          ethereum?.web3.utils.fromWei(balance, "ether").toLocaleString()
        );
      }
    } catch (error) {
      console.error("getBalances", error);
    }
  };

  const destinationPerOrigin = useMemo(() => {
    return parseFloat(directExchangeDetail.exchangeRate.toString()) > 0
      ? BigNumber(1).div(directExchangeDetail.exchangeRate)
      : BigNumber("0");
  }, [directExchangeDetail?.exchangeRate]);

  const unlockWallet = () => {
    mainConstant.setIsShowConnectModal(true);
  };

  const isInSufficientPoolBalances = useMemo(() => {
    return directExchangeDetail.poolBalances.isZero();
  }, [directExchangeDetail?.poolBalances]);

  const onUpdateNotification = () => {
    return {
      onTransactionHash: (txHash: any) => {
        setNotify({
          status: "processing",
          txHash,
        });
      },
      onError: (e: any, receipt?: any) => {
        setNotify({
          status: "failed",
          txHash: receipt ? receipt.transactionHash : null,
          errorReason: e?.shortMessage || "Transaction failed",
        });
      },
      onReceipt: ({
        transactionHash,
        status,
      }: {
        transactionHash: any;
        status: any;
      }) => {
        setNotify({
          status: status ? "success" : "failed",
          txHash: transactionHash,
        });
      },
    };
  };

  const onExchange = async (inputAmount: string) => {
    // const { confirmed, inputAmount } =
    //   await $refs.swapConfirmDirectExchange.open()
    // if (!confirmed) return
    try {
      setIsPending(true);
      const account = walletApi.account.current ?? "";
      console.log("onExchange 1", {
        inputAmount,
        contactAddress: directExchangeDetail.configExchange.contractAddress,
        account,
      });
      await exchange(
        directExchangeDetail.configExchange.contractAddress ?? "",
        account,
        toWei(inputAmount),
        onUpdateNotification()
      );
      console.log("onExchange 2", inputAmount);
      fetchData();
    } catch (err) {
      console.error(err);
    } finally {
      setIsPending(false);
    }
  };

  const approve = async () => {
    setIsPending(true);
    const account = walletApi.account.current ?? "";
    try {
      console.log("approve 1");
      await approveTokenToSpender(
        directExchangeDetail.configExchange.originToken.address ?? "",
        account,
        directExchangeDetail.configExchange.contractAddress ?? "",
        onUpdateNotification()
      );
      await checkStatus();
      console.log("approve 2");
    } catch (err) {
      console.error(err);
    } finally {
      setIsPending(false);
    }
  };

  const clearInputAmount = () => {
    setSupplyAmountTokenA("");
    setSupplyAmountTokenB("");
    // setSubmitButtonState("enterAmount");
  };

  const updateTradeInformation = async () => {
    if (!supplyAmountTokenA && !supplyAmountTokenB) {
      setTokenAPerTokenB("0.00");
      setTokenBPerTokenA("0.00");
      return;
    }

    try {
      if (
        directExchangeDetail.configExchange.originToken &&
        directExchangeDetail.configExchange.destinationToken &&
        supplyAmountTokenA &&
        supplyAmountTokenB
      ) {
        const route = await findRoute(
          directExchangeDetail.configExchange.originToken,
          directExchangeDetail.configExchange.destinationToken,
          parseFloat(toPrecision(supplyAmountTokenA)),
          parseFloat(toPrecision(supplyAmountTokenB)),
          firstInputToken
        );

        if (firstInputToken === "tokenA") {
          setSupplyAmountTokenB(route.minimumAmountOut);
        } else if (firstInputToken === "tokenB") {
          setSupplyAmountTokenA(route.minimumAmountOut);
        }

        setAmplifier(route.amplifier);
        setTradingFee(route.tradingFee);
        setRouteMap(route.routePathTextArray);
        setRouteMapText(route.routePathText);
        setPriceImpact(route.priceImpact);

        // setTooltipTradingFee(
        //   tooltipDynamicFee(route.tradingFee, route.inputToken.symbol)
        // );

        // amplifier = route.amplifier;
        // tradingFee = route.tradingFee;
        // routeMap = route.routePathTextArray;
        // routeMapText = route.routePathText;
        // priceImpact = route.priceImpact;
        // tooltipTradingFee = tooltipDynamicFee(
        //   tradingFee,
        //   route.inputToken.symbol
        // );

        setLastTokenAPerBPrice(tokenAPerTokenB);

        setTokenBPerTokenA(
          BigNumber(supplyAmountTokenB)
            .div(BigNumber(supplyAmountTokenA))
            .toFixed(8)
            .toString()
        );

        setTokenAPerTokenB(
          BigNumber(supplyAmountTokenA)
            .div(BigNumber(supplyAmountTokenB))
            .toFixed(8)
            .toString()
        );

        setIsCallSmartContractError(false);
      } else {
        setIsCallSmartContractError(true);
      }
    } catch (error) {
      console.error("updateTradeInformation", error);
      setIsCallSmartContractError(true);
    }

    // set flag to display price update on modal
    // if (
    //   isSwapConfirmModalOpen &&
    //   !BigNumber(lastTokenAPerBPrice).isEqualTo(tokenAPerTokenB)
    // ) {
    //   setPriceChangingOnModal(true);
    // }
  };

  const amountChanged = async (inputToken: string, amount: string = "") => {
    try {
      console.log("amountChanged", { inputToken, amount });
      setFirstInputToken(inputToken);

      console.log("amountChanged", {
        og: directExchangeDetail.configExchange.originToken,
        des: directExchangeDetail.configExchange.destinationToken,
        amount,
      });
      if (
        directExchangeDetail.configExchange.originToken &&
        directExchangeDetail.configExchange.destinationToken &&
        amount
      ) {
        console.log("inputToken", inputToken);

        if (inputToken === "tokenA") {
          const tokenB = BigNumber(amount).multipliedBy(
            directExchangeDetail.exchangeRate
          );
          setSupplyAmountTokenB(tokenB.toString());
        } else if (inputToken === "tokenB") {
          const tokenA = BigNumber(amount).dividedBy(
            directExchangeDetail.exchangeRate
          );
          setSupplyAmountTokenA(tokenA.toString());
        }

        // updateTradeInformation();

        setIsCallSmartContractError(false);
      } else {
        setIsCallSmartContractError(true);
      }
    } catch (error) {
      console.error("amountChanged", error);
      setIsCallSmartContractError(true);
    }
  };

  const updateAmountTokenA = (supplyAmount: string | null) => {
    if (supplyAmountTokenA === supplyAmount) return;

    clearInputAmount();

    // handleSubmitButtonState();
    if (!supplyAmount || isNaN(parseFloat(supplyAmount))) return;
    setMinMaxTradingText("Min received");
    setSupplyAmountTokenA(supplyAmount);

    if (!parseFloat(supplyAmount)) return;
    amountChanged("tokenA", supplyAmount);
  };

  const updateAmountTokenB = (supplyAmount: string | null) => {
    if (supplyAmountTokenB === supplyAmount) return;

    clearInputAmount();

    // handleSubmitButtonState();
    // setMinMaxTradingText("Min received");

    if (!supplyAmount || isNaN(parseFloat(supplyAmount))) return;
    setMinMaxTradingText("Max sold");
    setSupplyAmountTokenB(supplyAmount);
    if (!parseFloat(supplyAmount)) return;
    amountChanged("tokenB", supplyAmount);
  };

  return (
    <div className="md:container p-0">
      <div className="xl:ml-32 xl:max-w-[976px]">
        <PageHeader
          title="GET OMNI POINTS"
          subTitle="Convert your tokens directly to Omni Points with the fixed platform exchange rate"
          subTitle2="1 USDV ($1.0000) = 100 OMNI POINTS ($1.0000)"
          subTitle2ClassName="text-[#E32970]"
        ></PageHeader>
        <div className="flex flex-col items-center">
          <div className="px-4 md:px-0 w-full">
            <div className="flex flex-col md:flex-row items-center justify-center relative">
              <div className="flex flex-col w-full md:w-[400px] 2xl:w-full col-span-5">
                From
                <div className="p-2"></div>
                <AmountInput
                  // className="w-full md:w-[400px] 2xl:w-full col-span-5"
                  testId="pool_token_in"
                  token={directExchangeDetail.configExchange.originToken}
                  amount={supplyAmountTokenA}
                  balance={displayBalanceTokenA}
                  isInsufficient={isInsufficientBalance}
                  onUpdateAmount={updateAmountTokenA}
                />
              </div>
              <div
                className="relative cursor-pointer transform rotate-90 md:rotate-0 m-2 md:pt-6 mx-[17px] col-span-1"
                data-testid="swap_switch_img"
                onClick={() => {}}
              >
                <img
                  className="min-w-[32px] h-8 w-full"
                  src={
                    require("/src/assets/icons/swap/ico_exchange.svg").default
                  }
                  alt="exchange"
                />
              </div>
              <div className="flex flex-col w-full md:w-[400px] 2xl:w-full col-span-5">
                To
                <div className="p-2"></div>
                <AmountInput
                  // className="w-full md:w-[400px] 2xl:w-full col-span-5"
                  testId="pool_token_in"
                  token={directExchangeDetail.configExchange.destinationToken}
                  amount={supplyAmountTokenB}
                  balance={displayBalanceTokenB}
                  isInsufficient={isInsufficientBalance}
                  onUpdateAmount={updateAmountTokenB}
                  hideMaxButton={true}
                />
              </div>
            </div>
          </div>
        </div>
        {supplyAmountTokenA && false && (
          <div className="w-full flex flex-col md:flex-row mx-auto mt-10 mb-16 md:mb-8 px-4 md:pl-0 md:pr-0">
            <Table position="top" className="flex-1 md:mr-2 text-xs border-b-0">
              {/* First Row: Min/Max Trading */}
              <TableRow
                leftSlot={
                  <div
                    className="flex items-center text-velo-label"
                    data-testid="swap_min_max_trading_txt"
                  >
                    <ToolTip
                      className="mr-1"
                      iconSize="base"
                      iconClass="leading-none text-velo-primary"
                      message="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
                    />
                    {minMaxTradingText}
                  </div>
                }
                rightSlot={
                  <div data-testid="swap_min_max_trading_value">
                    <span className="text-sm">{minMaxTradingAmount + " "}</span>
                    <span className="text-sm">
                      {minMaxTradingText === "Min received"
                        ? directExchangeDetail.configExchange.destinationToken
                            ?.name
                        : directExchangeDetail.configExchange.originToken?.name}
                    </span>
                  </div>
                }
              />

              {/* Second Row: Trading Fee */}
              <TableRow
                className="mt-2"
                leftSlot={
                  <div
                    className="flex items-center text-velo-label"
                    data-testid="swap_trading_fee_txt"
                  >
                    <ToolTip
                      className="mr-1"
                      iconSize="base"
                      iconClass="leading-none text-base text-velo-primary"
                      message={
                        "Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
                      }
                    />
                    Trading fee
                  </div>
                }
                rightSlot={
                  <div data-testid="swap_trading_fee_value">
                    <span className="text-sm">{tradingFee + " "}</span>
                    <span className="text-sm">
                      {directExchangeDetail.configExchange.originToken?.name}
                    </span>
                  </div>
                }
              />

              {/* Third Row: Price Impact */}
              <TableRow
                className="mt-2"
                leftSlot={
                  <div
                    className="flex items-center text-velo-label"
                    data-testid="swap_price_impact_txt"
                  >
                    <ToolTip
                      className="mr-1"
                      iconSize="base"
                      iconClass="leading-none text-sm text-velo-primary"
                      message="The difference between the market price and estimated price due to trade size."
                    />
                    Price impact
                  </div>
                }
                rightSlot={
                  <div
                    className={`${getPriceImpactClass()} text-sm`}
                    data-testid="swap_price_impact_value"
                  >
                    {(parseFloat(priceImpact) > 99.99
                      ? 99.99
                      : parseFloat(priceImpact)
                    ).toFixed(2)}
                    %
                  </div>
                }
              />

              {/* Fourth Row: Slippage Tolerance */}
              <TableRow
                className="mt-2"
                leftSlot={
                  <div
                    className="ml-[18px] text-velo-label"
                    data-testid="swap_slippage_txt"
                  >
                    <MaterialIcon className="text-base mr-1 leading-none">
                      launch
                    </MaterialIcon>
                    Route
                  </div>
                }
                rightSlot={
                  <div className="text-sm" data-testid="swap_slippage_value">
                    {routeMapText}
                  </div>
                }
              />
            </Table>
          </div>
        )}
        {/* <div className="flex items-center justify-center w-full xl:col-start-2"> */}
        {/* className="w-full uppercase"  */}
        {/* <Button className="w-full uppercase" onClick={unlockWallet}>
                Unlock Wallet
              </Button> */}
        <ButtonWrapper>
          {!walletApi.isConnected ? (
            <Button
              className="w-full mt-6 mb-6 uppercase md:w-72"
              onClick={unlockWallet}
            >
              UNLOCK WALLET
            </Button>
          ) : isInSufficientPoolBalances ? (
            <Button className="w-full mt-6 mb-4 uppercase md:w-72" disabled>
              Out of {directExchangeDetail.configExchange.destinationToken.name}
            </Button>
          ) : isPending ? (
            <Button disabled className="w-full mt-6 mb-4 uppercase md:w-72">
              Processing...
            </Button>
          ) : !isApproved ? (
            <Button
              className="w-full mt-6 mb-4 uppercase md:w-72"
              onClick={approve}
            >
              Approve {directExchangeDetail.configExchange.originToken.name}
            </Button>
          ) : (
            <Button
              className="w-full mt-6 mb-4 uppercase md:w-72"
              onClick={() => setIsConfirmModalOpen(true)}
            >
              Exchange
            </Button>
          )}
        </ButtonWrapper>
        <div className="flex items-center justify-center">
          <div className="flex items-center cursor-pointer" onClick={addToken}>
            <span className="text-lg text-transparent bg-clip-text bg-velo-primary-gradient4 mr-1">
              →
            </span>
            <div className="text-sm">
              Add {directExchangeDetail.configExchange.originToken.name} token
              to wallet
            </div>
          </div>
        </div>
      </div>

      {/* Assuming there's a SwapConfirmDirectExchange component */}
      <ConfirmDirectOmniModal
        // ref={swapConfirmDirectExchange}
        onDirectExchange={onExchange}
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setIsConfirmModalOpen}
        directExchangeDetail={directExchangeDetail}
        configExchange={directExchangeDetail.configExchange}
        destinationPerOrigin={destinationPerOrigin}
        originAmount={supplyAmountTokenA ?? "0"}
        setOriginalAmount={setSupplyAmountTokenA}
      />
    </div>
  );
};

export default ExchangeOmni;
