import { ethers } from "ethers";
import config from "../config";
import { useEthereum } from "../contexts/etherruemContext";
import { useSlippageState } from "../contexts/slippageContext";
import BigNumber from "bignumber.js";
import { useWriteContract } from "wagmi";
import useLazyReadContractAsync from "./useLazyReadContractAsync";
import AMMRouter from "../../src/assets/abi/swap/AMMRouter.json";
import DMMRouter from "../../src/assets/abi/swap/DMMRouter.json";
import { useEffect, useState } from "react";

const noOp = () => {};
const noOpAny = (value: any) => {};

const useLiquidity = () => {
  const ethereum = useEthereum();
  const [isReady, setIsReady] = useState(false);
  const { writeContractAsync } = useWriteContract();
  const { lazyReadContract } = useLazyReadContractAsync();

  const { slippage } = useSlippageState();

  useEffect(() => {
    if (ethereum.contracts) {
      setIsReady(true);
    }
  }, [ethereum]);

  const getListReserveInPool = async (lpNames: string[]) => {
    if (ethereum.contracts) {
      try {
        return await Promise.all(
          lpNames.map((lpName) => getReserveInPool(lpName))
        );
      } catch (e) {
        console.log("getListReserveInPool", e);
      }
    }
    return [];
  };

  const getReserveInPool = async (lpName: string) => {
    if (
      ethereum.contracts &&
      ethereum.contracts.tokens[lpName]._address !== ""
    ) {
      try {
        return await ethereum.contracts.tokens[lpName].methods
          .getReserves()
          .call();
      } catch (e) {
        console.error("lpname", lpName, e);
      }
    }
    return "0";
  };

  const getTotalPoolShare = async (lpName: string, address: string) => {
    if (
      ethereum.contracts &&
      ethereum.contracts.tokens[lpName]._address !== ""
    ) {
      const totalSupply = await getLpTotalSupply(lpName);
      const lpBalance = await getBalanceOfLP(lpName, address);
      return {
        totalSupply,
        lpBalance,
      };
    }
    return {
      totalSupply: "0",
      lpBalance: "0",
    };
  };

  const getTokenConfigByAddress = (address: string) => {
    let token = null;
    const configTokenList = Object.keys(config.token).map((k) => {
      return config.token[k];
    });
    token = configTokenList.find(
      (element) =>
        element?.address &&
        element.address.toUpperCase() === address.toUpperCase()
    );

    return token;
  };

  const getToken0AndToken1InLp = async (lpName: string) => {
    let token0address = 0;
    let token1address = 0;
    try {
      if (
        ethereum.contracts.tokens &&
        ethereum.contracts.tokens[lpName]._address !== ""
      ) {
        token0address = await ethereum.contracts.tokens[lpName].methods
          .token0()
          .call();
        token1address = await ethereum.contracts.tokens[lpName].methods
          .token1()
          .call();
      }
    } catch (e) {
      console.log("e", e);
    }

    const token0 = getTokenConfigByAddress(token0address.toString());
    const token1 = getTokenConfigByAddress(token1address.toString());
    return { token0, token1 };
  };

  const getBalanceOfLP = async (lpName: string, address: string) => {
    if (
      ethereum.contracts != null &&
      ethereum.contracts.tokens[lpName]._address !== ""
    ) {
      try {
        return await ethereum.contracts.tokens[lpName].methods
          .balanceOf(address)
          .call();
      } catch (e) {
        return BigNumber(0);
      }
    }
    return BigNumber(0);
  };

  const getLpTotalSupply = async (lpName: string) => {
    if (
      ethereum.contracts != null &&
      ethereum.contracts.tokens[lpName]._address !== ""
    ) {
      return await ethereum.contracts.tokens[lpName].methods
        .totalSupply()
        .call();
    }
    return "0";
  };

  const addLiquidityETHOnAMM = async (
    ethAmount: string,
    tokenAddress: string,
    tokenAmount: string,
    minTokenAmount: string,
    amountEthMin: string,
    wallet: string,
    deadline: string,
    { onError = noOpAny, onReceipt = noOpAny, onTransactionHash = noOpAny }
  ) => {
    await writeContractAsync(
      // @ts-ignore
      {
        abi: AMMRouter,
        value: BigInt(ethAmount),
        // @ts-ignore
        from: wallet,
        // @ts-ignore
        address: config.ammRouterContractAddress,
        functionName: "addLiquidityETH",
        args: [
          tokenAddress,
          tokenAmount,
          minTokenAmount,
          amountEthMin,
          wallet,
          deadline || 1,
        ],
      },
      {
        onSuccess(data: any) {
          onReceipt({ transactionHash: data, status: true });
          console.log("swap success", data);
          // if (!data) {
          //   throw new Error("erc20 transfer failed");
          // }
          return data;
        },
        onError: (error: any) => {
          onError(error);
          console.log("useEthWalletTransfer: erc20 transfer, error", error);
          throw error;
        },
      }
    );
    // ethereum.contracts.ammRouter.methods
    //   .addLiquidityETH(
    //     tokenAddress,
    //     tokenAmount,
    //     minTokenAmount,
    //     amountEthMin,
    //     wallet,
    //     deadline
    //   )
    //   .send({
    //     from: wallet,
    //     value: ethAmount,
    //   })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const addLiquidityOnAMM = async (
    tokenA: string,
    tokenB: string,
    amountA: string,
    amountB: string,
    amountAMin: string,
    amountBMin: string,
    wallet: string,
    deadline: string,
    { onError = noOpAny, onReceipt = noOpAny, onTransactionHash = noOpAny }
  ) => {
    try {
      await writeContractAsync(
        // @ts-ignore
        {
          abi: AMMRouter,
          // @ts-ignore
          from: wallet,
          // @ts-ignore
          address: config.ammRouterContractAddress,
          functionName: "addLiquidity",
          args: [
            tokenA,
            tokenB,
            amountA,
            amountB,
            amountAMin,
            amountBMin,
            wallet,
            deadline,
          ],
        },
        {
          onSuccess(data: any) {
            onReceipt({ transactionHash: data, status: true });
            console.log("swap success", data);
            // if (!data) {
            //   throw new Error("erc20 transfer failed");
            // }
            return data;
          },
          onError: (error: any) => {
            onError(error);
            console.log("useEthWalletTransfer: erc20 transfer, error", error);
            // throw error;
          },
        }
      );
      // ethereum.contracts.ammRouter.methods
      //   .addLiquidity(
      //     tokenA,
      //     tokenB,
      //     amountA,
      //     amountB,
      //     amountAMin,
      //     amountBMin,
      //     wallet,
      //     deadline
      //   )
      //   .send({
      //     from: wallet,
      //   })
      //   .on("transactionHash", onTransactionHash)
      //   .on("receipt", onReceipt)
      //   .catch(onError);
    } catch (e) {
      console.log("addLiquidityOnAMM error", e);
    }
  };

  const addLiquidityOnDMM = async (
    tokenA: string,
    tokenB: string,
    poolAddress: string,
    amountTokenA: string,
    amountTokenB: string,
    amountAMin: string,
    amountBMin: string,
    wallet: string,
    trxDeadline: string,
    { onError = noOpAny, onReceipt = noOpAny, onTransactionHash = noOpAny }
  ) => {
    const reserveRatio = [0, ethers.MaxUint256];
    await writeContractAsync(
      // @ts-ignore
      {
        abi: DMMRouter,
        // @ts-ignore
        from: wallet,
        // @ts-ignore
        address: config.dmmRouterContractAddress,
        functionName: "addLiquidity",
        args: [
          tokenA,
          tokenB,
          poolAddress,
          amountTokenA,
          amountTokenB,
          amountAMin,
          amountBMin,
          reserveRatio,
          wallet,
          trxDeadline,
        ],
      },
      {
        onSuccess(data: any) {
          onReceipt({ transactionHash: data, status: true });
          console.log("swap success", data);
          // if (!data) {
          //   throw new Error("erc20 transfer failed");
          // }
          return data;
        },
        onError: (error: any) => {
          onError(error);
          console.log("useEthWalletTransfer: erc20 transfer, error", error);
          // throw error;
        },
      }
    );
    // ethereum.contracts.dmmRouter.methods
    //   .addLiquidity(
    //     tokenA,
    //     tokenB,
    //     poolAddress,
    //     amountTokenA,
    //     amountTokenB,
    //     amountAMin,
    //     amountBMin,
    //     reserveRatio,
    //     wallet,
    //     trxDeadline
    //   )
    //   .send({
    //     from: wallet,
    //   })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const slippageCalculation = (estimateAmountOut: any) => {
    const amountOutMin = BigNumber(estimateAmountOut).times(slippage).div(100);
    return BigNumber(estimateAmountOut)
      .minus(amountOutMin)
      .toFixed(17)
      .toString();
  };

  const removeLiquidityETHOnAMM = async (
    tokenAddress: string,
    liquidity: string,
    amountTokenMin: string,
    amountNativeMin: string,
    deadline: string,
    wallet: string,
    { onError = noOpAny, onReceipt = noOpAny, onTransactionHash = noOpAny }
  ) => {
    try {
      await writeContractAsync(
        // @ts-ignore
        {
          abi: AMMRouter,
          // @ts-ignore
          from: wallet,
          // @ts-ignore
          address: config.ammRouterContractAddress,
          functionName: "removeLiquidityETH",
          args: [
            tokenAddress,
            liquidity,
            amountTokenMin,
            amountNativeMin,
            wallet,
            deadline,
          ],
        },
        {
          onSuccess(data: any) {
            onReceipt({ transactionHash: data, status: true });
            console.log("swap success", data);
            // if (!data) {
            //   throw new Error("erc20 transfer failed");
            // }
            return data;
          },
          onError: (error: any) => {
            onError(error);
            console.log("useEthWalletTransfer: erc20 transfer, error", error);
            // throw error;
          },
        }
      );
    } catch (e) {
      console.log(e);
    }

    // ethereum.contracts.ammRouter.methods
    //   .removeLiquidityETH(
    //     tokenAddress,
    //     liquidity,
    //     amountTokenMin,
    //     amountNativeMin,
    //     wallet,
    //     deadline
    //   )
    //   .send({ from: wallet })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const removeLiquidityOnAMM = async (
    tokenA: string,
    tokenB: string,
    liquidity: string,
    amountAMin: string,
    amountBMin: string,
    wallet: string,
    trxDeadline: string,
    { onError = noOpAny, onReceipt = noOpAny, onTransactionHash = noOpAny }
  ) => {
    try {
      await writeContractAsync(
        // @ts-ignore
        {
          abi: AMMRouter,
          // @ts-ignore
          from: wallet,
          // @ts-ignore
          address: config.ammRouterContractAddress,
          functionName: "removeLiquidity",
          args: [
            tokenA,
            tokenB,
            liquidity,
            amountAMin,
            amountBMin,
            wallet,
            trxDeadline,
          ],
        },
        {
          onSuccess(data: any) {
            onReceipt({ transactionHash: data, status: true });
            console.log("swap success", data);
            // if (!data) {
            //   throw new Error("erc20 transfer failed");
            // }
            return data;
          },
          onError: (error: any) => {
            onError(error);
            console.log("useEthWalletTransfer: erc20 transfer, error", error);
            // throw error;
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
    // ethereum.contracts.ammRouter.methods
    //   .removeLiquidity(
    //     tokenA,
    //     tokenB,
    //     liquidity,
    //     amountAMin,
    //     amountBMin,
    //     wallet,
    //     trxDeadline
    //   )
    //   .send({ from: wallet })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const removeLiquidityOnDMM = async (
    tokenA: string,
    tokenB: string,
    poolAddress: string,
    liquidity: string,
    amountAMin: string,
    amountBMin: string,
    wallet: string,
    trxDeadline: string,
    { onError = noOpAny, onReceipt = noOpAny, onTransactionHash = noOpAny }
  ) => {
    try {
      await writeContractAsync(
        // @ts-ignore
        {
          abi: DMMRouter,
          // @ts-ignore
          from: wallet,
          // @ts-ignore
          address: config.dmmRouterContractAddress,
          functionName: "removeLiquidity",
          args: [
            tokenA,
            tokenB,
            poolAddress,
            liquidity,
            amountAMin,
            amountBMin,
            wallet,
            trxDeadline,
          ],
        },
        {
          onSuccess(data: any) {
            onReceipt({ transactionHash: data, status: true });
            console.log("swap success", data);
            // if (!data) {
            //   throw new Error("erc20 transfer failed");
            // }
            return data;
          },
          onError: (error: any) => {
            onError(error);
            console.log("useEthWalletTransfer: erc20 transfer, error", error);
            // throw error;
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
    // ethereum.contracts.dmmRouter.methods
    //   .removeLiquidity(
    //     tokenA,
    //     tokenB,
    //     poolAddress,
    //     liquidity,
    //     amountAMin,
    //     amountBMin,
    //     wallet,
    //     trxDeadline
    //   )
    //   .send({ from: wallet })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  return {
    getListReserveInPool,
    getReserveInPool,
    getTokenConfigByAddress,
    getToken0AndToken1InLp,
    getBalanceOfLP,
    getLpTotalSupply,
    getTotalPoolShare,
    addLiquidityETHOnAMM,
    addLiquidityOnAMM,
    addLiquidityOnDMM,
    slippageCalculation,
    removeLiquidityETHOnAMM,
    removeLiquidityOnAMM,
    removeLiquidityOnDMM,
    isReady,
  };
};

export default useLiquidity;
