import BigNumber from "bignumber.js";
import React, { useState, useEffect } from "react";
import { digit, networkType } from "../constants";
import Modal from "./modal";
import Button from "./button";
import InputText from "./input/text";
import useWalletHelpers from "../hooks/useWalletHelpers";
import TokenSelect from "./tokenSelect";
import { Token } from "../interfaces/token";

interface Props {
  testId?: string;
  title?: string | null;
  altText?: string | null;
  amount?: string;
  balance?: string;
  disabled?: boolean;
  color?: string;
  bgColor?: string;
  borderClass?: string;
  hideMaxButton?: boolean;
  showCustomButton?: boolean;
  isSelectToken?: boolean;
  isShowPercentage?: boolean;
  tokenList?: Token[];
  isShowAddTokenWallet?: boolean;
  token?: Token | null;
  // onUpdateAmount: (value: string) => void;
  // onBlur?: () => void;
  // updateToken: (token: Token) => void;
  // updateIsInsufficientBalance: (value: boolean) => void;
  onUpdateAmount: (amount: string | null) => void;
  className?: string;
  isInsufficient: boolean;
  onSelectToken?: (selectedToken: Token) => void;
  extraText?: string;
}

const listPercentage = [25, 50, 75, 100];

const AmountInput: React.FC<Props> = ({
  testId = "",
  title = null,
  altText = null,
  amount = "",
  balance = "0",
  disabled = false,
  color = "white",
  bgColor = "",
  borderClass = "border border-[#19506F]",
  hideMaxButton = false,
  showCustomButton = false,
  isSelectToken = false,
  isShowPercentage = false,
  tokenList = [],
  isShowAddTokenWallet = false,
  token,
  onUpdateAmount,
  className,
  isInsufficient,
  onSelectToken = () => {},
  extraText,
}) => {
  // const [token, setTokenValue] = useState<Token>();
  const [isInsufficientBalance, setIsInsufficientBalance] =
    useState(isInsufficient);
  const [isMaxAmount, setIsMaxAmount] = useState(false);
  const [onFocus, setOnFocus] = useState(false);

  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  const { addTokenOnWallet } = useWalletHelpers();

  useEffect(() => {
    checkInsufficientBalance(amount);
  }, [amount, balance]);

  const invalidInput = () => {
    return disabled || !token?.address;
  };
  const tokenName = () => {
    return !!token?.name ? token?.name : "Select token";
  };

  const onlyNumber = (evt: any) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (amount) {
      if (evt.keyCode === 46 && amount.split(".").length === 2) {
        return evt.preventDefault();
      }
    }
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return evt.preventDefault();
    } else {
      return true;
    }
  };
  const onBlur = () => {
    setOnFocus(false);
    const amounts = BigNumber(amount);
    if (amounts.isZero()) {
      onUpdateAmount(null);
    }
    // $emit("onBlur");
  };
  const onUpdateAmountValue = (value: any) => {
    let values = value.replace(/(?!\.|\d)([\D]+)/gi, "");
    values = values.replace(/^0(00*)/gi, "0");
    values = values.replace(/^(\.)/gi, "0.");
    values = values.replace(/(?!^0\.)(?!^0$)(^0)/gi, "");
    checkInsufficientBalance(values);
    onUpdateAmount(values);
  };
  const onClickMax = () => {
    if (invalidInput() || parseFloat(balance) <= 0) return;
    onUpdateAmount(balance.toString());
  };

  const onClickPercentage = (percent: number) => {
    const _balance = BigNumber(balance);
    if (!_balance.isZero()) {
      const percentOfBalance = _balance.multipliedBy(percent / 100).toString();
      checkInsufficientBalance(percentOfBalance);
      onUpdateAmount(percentOfBalance);
    }
  };

  const checkInsufficientBalance = (value: string) => {
    const _balance = BigNumber(balance);
    const _value = BigNumber(value);
    setIsInsufficientBalance(
      _value.isLessThanOrEqualTo(0) || _value.isGreaterThan(_balance)
    );
    setIsMaxAmount(_value.isEqualTo(_balance));
  };

  const isPercentActive = (percent: number) => {
    const _balance = BigNumber(balance);
    const percentOfBalance = _balance.multipliedBy(percent / 100);
    const _value = BigNumber(amount);
    return _value.isEqualTo(percentOfBalance);
  };

  const isZeroValue = () => {
    const _balance = BigNumber(balance);
    return _balance.isZero();
  };

  const selectTokenModel = () => {
    if (isSelectToken) {
      setIsSelectModalOpen(true);
    }
  };

  const closeSelectTokenModel = () => {
    setIsSelectModalOpen(false);
  };

  const handleClickSelectToken = (selectedToken: Token) => {
    closeSelectTokenModel();
    // setTokenValue({ ...selectedToken });

    onSelectToken(selectedToken);
    // $emit("update:token", selectedToken);
  };

  const addToken = (token: Token) => {
    const { address, image, symbol } = token;
    addTokenOnWallet(
      address ?? "",
      symbol,
      image ?? "",
      digit.TOKEN_DIGIT,
      networkType.ERC20,
      {
        onReceipt: () => console.log("added!"),
        onError: (error: any) => console.log(error),
      }
    );
  };

  return (
    <div className={className}>
      {title && (
        <div className="pb-4 text-xs font-semibold text-wisp-pink">{title}</div>
      )}
      <div
        data-testid={`${testId}_header_txt`}
        className={`rounded-[2px] p-4 ${bgColor} ${borderClass}`}
      >
        <div className="flex justify-between h-8">
          <div
            data-testid={`${testId}_select_token_btn`}
            className={`flex justify-center items-center mr-2 ${
              isSelectToken ? "cursor-pointer" : ""
            }`}
            onClick={selectTokenModel}
          >
            <div className="pr-2 h-6">
              <picture>
                {token && token.image ? (
                  <img
                    src={token?.image}
                    // src={
                    //   token && token.image
                    //     ? token.image
                    //     : "/swap/ico_coin_unknow.svg"
                    // }
                    className="h-full"
                    alt={token && token.name}
                  />
                ) : (
                  <img
                    src={
                      require("/src/assets/icons/swap/ico_coin_unknow.svg")
                        .default
                    }
                    // src={
                    //   token && token.image
                    //     ? token.image
                    //     : "/swap/ico_coin_unknow.svg"
                    // }
                    className="h-full"
                    alt={"Select token"}
                  />
                )}
              </picture>
            </div>
            <div
              data-testid={`${testId}_select_token_txt`}
              className="text-base text-wisp-pink"
            >
              {tokenName()}
            </div>
            <div className="px-1"></div>
            {isSelectToken && (
              <img
                src={
                  require("/src/assets/icons/general/arrowdown-icon.svg")
                    .default
                }
                className="h-full w-[15px]"
                alt={"Select token"}
              />
            )}
          </div>
          {!hideMaxButton && (
            <Button
              type="outline"
              className={`text-sm ${
                invalidInput() ? "cursor-not-allowed" : ""
              }`}
              size="small"
              state="primary"
              data-testid={`${testId}_max_btn`}
              // color={isMaxAmount ? "velo-textfield" : "wisp-pink"}
              disabled={invalidInput()}
              onClick={onClickMax}
            >
              MAX
            </Button>
          )}
        </div>

        <div
          className={`flex mt-2 p-2 h-10 justify-between rounded-[2px] border border-[#4E4E4E] ${
            invalidInput()
              ? ""
              : amount || onFocus
              ? "border border-velo-pink"
              : ""
          }`}
        >
          <span className="flex justify-end flex-grow">
            <InputText
              type="number"
              data-testid={`${testId}_input`}
              placeholder={onFocus ? "" : extraText ? extraText : "0.0000"}
              value={amount}
              externalClass={`flex-grow w-40 border-none outline-none overflow-hidden text-right text-number ${
                invalidInput()
                  ? "cursor-not-allowed border-velo-disable placeholder-velo-grey"
                  : "placeholder-velo-grey"
              }`}
              disabled={invalidInput()}
              onInput={(e) => onUpdateAmountValue(e.currentTarget.value)}
              onBlur={() => {
                onBlur();
                setOnFocus(false);
              }}
              onFocus={() => setOnFocus(true)}
              onKeyDown={(e) => e.key === " " && e.preventDefault()}
              onKeyPress={onlyNumber}
            />
          </span>
        </div>

        <div
          className={`pt-2 ${
            invalidInput() ? "text-[#8D8D8D] opacity-50" : "text-wisp-pink"
          }`}
        >
          <span className="text-sm" data-testid={`${testId}_balance_txt`}>
            {altText || "Balance"}:{" "}
          </span>
          <span className="text-sm" data-testid={`${testId}_balance_value`}>
            {parseFloat(balance) > 0 ? "~" : ""}
            {parseFloat(balance).toFixed(4)}
          </span>
        </div>

        {isShowPercentage && (
          <div className="flex mt-4">
            {listPercentage.map((item, index) => (
              <div
                key={index}
                className={`w-12 h-7 mr-2 rounded flex items-center justify-center cursor-pointer ${
                  isZeroValue()
                    ? "bg-velo-disable text-velo-disable-2 cursor-not-allowed"
                    : isPercentActive(item)
                    ? "bg-velo-primary text-velo-textfield"
                    : "bg-velo-disable text-velo-disable-2"
                }`}
                onClick={() => !invalidInput && onClickPercentage(item)}
              >
                <span className="text-xs font-semibold">{item}%</span>
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal
        widthClass="w-[362px]"
        isShowDialog={isSelectModalOpen}
        onClose={closeSelectTokenModel}
      >
        <div className="text-white text-[20px] font-medium uppercase mb-8">
          Select a token
        </div>
        <TokenSelect
          testId={`${testId}`}
          value={token?.symbol ?? ""}
          options={tokenList}
          isShowAddTokenWallet={isShowAddTokenWallet}
          onClick={handleClickSelectToken}
          onClickAddToken={addToken}
        />
      </Modal>
    </div>
  );
};

export default AmountInput;
